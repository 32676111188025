import { createRouter, createWebHistory } from "vue-router";
import guard from "../guard";
import Login from "../components/auth/template/Login";
import Dashboard from "../components/dashboard/template/Dashboard";
import BlogPost from "../components/Blog/BlogPost/template/BlogPost";
import AddBlogPost from "../components/Blog/BlogPost/template/AddBlogPost"
import UpdateBlogPost from "../components/Blog/BlogPost/template/UpdateBlogPost";
import Profile from "../components/profile/template/Profile";
import OurValues from "../components/AboutUs/OurValues/template/OurValues";
import ContactMessageList from "../components/Contact/template/ContactMessageList";
//CMS
import HeaderBannerCMS from "../components/CMS/HeaderCMS/HeaderBannerCMS/template/HeaderBannerCMS"; 
import FooterCMS from "../components/CMS/FooterCMS/template/FooterCMS";
import FooterPayment from "../components/CMS/FooterPayment/template/FooterPaymentGateway";
import TrendingSectionCMS from "../components/CMS/TrendingSectionCMS/template/TrendingSectionCMS";
import ContactUsPageCMS from "../components/CMS/ContactUsCMS/template/ContactUsCMS";
import AboutUsPageCMS from "../components/CMS/AboutPageCMS/template/AboutPageCMS";
import BlogPageCMS from "../components/CMS/BlogCMS/template/BlogCMS";
import Faq from "../components/CMS/FaqPageCMS/template/FaqPageCMS";
import RefundPageCMS from "../components/CMS/RefundPageCMS/template/RefundPageCMS";
import PrivacyPageCMS from "../components/CMS/PrivacyPageCMS/template/PrivacyPageCMS";
import HeroSectionSlider from "../components/CMS/hero-section-slider/template/HeroSectionSlider";
import ShopPageCms from "../components/CMS/ShopPageCMS/template/ShopPageCms";
import CollectionPage from "../components/CMS/collection-page/template/CollectionPage";
import LooksYouWillLoveCMS from "../components/CMS/LooksYouWillLoveCMS/template/LooksYouWillLoveCMS";
import ClearenceSaleSectionCMS from "../components/CMS/ClearenceSaleSectionCMS/template/ClearenceSaleSectionCMS";
import InstagramSectionCMS from "../components/CMS/InstagramSectionCMS/template/InstagramSectionCMS";
import LatestArrivalCMS from "../components/CMS/LatestArrivalCMS/template/LatestArrivalCMS";
import LatestNewsCMS from "../components/CMS/LatestNewsCMS/template/LatestNewsCMS";
import ProductDetailsPageCMS from "../components/CMS/ProductDetailsPageCMS/template/ProductDetailsPageCMS";
import LatestCollection from "../components/CMS/LatestCollectionSection/template/LatestCollectionSection";
import SitePageTitleCMS from "../components/CMS/SitePageTitleCMS/template/SitePageTitleCMS";

// crud
import SocialLinks from "../components/social-links/template/SocialLinks";
import InstagramService from "../components/InstagramSection/template/InstagramSection";

//product
import AddProduct from "../components/Product/AddProduct/template/AddProduct";
import UpdateProduct from "../components/Product/UpdateProduct/template/UpdateProduct";
import ProductList from "../components/Product/ProductList/template/Product";
import ProductCategory from "../components/Product/product-category/template/ProductCategory";
import ProductSize from "../components/Product/product-size/template/ProductSize";
import ProductSizeGuide from "../components/Product/ProductSizeGuide/template/ProductSizeGuide";
import AddNewSizeGuide from "../components/Product/ProductSizeGuide/template/AddNewSizeGuide";
import EditSizeGuide from "../components/Product/ProductSizeGuide/template/EditSizeGuide";
import ProductSale from "../components/Product/product-sale/template/ProductSale";
import ProductColor from "../components/Product/product-color/template/ProductColor";
import ProductCare from "../components/Product/product-care/template/ProductCare";
import ProductOffer from "../components/Product/product-offer/template/ProductOffer";
import ProductPerk from "../components/Product/product-perk/template/ProductPerk";
import ProductBulkUpload from "../components/Product/ProductBulkUpload/template/ProductBulkUpload";
import ProductStockIn from "../components/Product/ProductStockIn/template/ProductStockIn";


//Payment
import PaymentConfiguration from "../components/Payment/payment-configuration/template/PaymentConfiguration";
import PaymentList from "../components/Payment/PaymentList/template/PaymentList";

//Tax
import SalesTax from "../components/SalesTax/template/SalesTax";

//Order
import CreateOrder from "../components/Order/CreateOrder/template/CreateOrder";
import OrderList from "../components/Order/OrderList/template/OrderList";
import OrderDetails from "../components/Order/OrderDetails/template/OrderDetails";

//Shipping Settings
import Ups from "../components/ShippingSettings/Ups/template/Ups";
import Fedex from "../components/ShippingSettings/Fedex/template/Fedex";
import ShippingMethods from "../components/shipping-methods/template/ShippingMethods";
import ShippingCMS from "../components/CMS/ShippingCMS/template/ShippingCMS";

//user
import Admin from "../components/user/template/Admin";
import Customer from "../components/user/template/Customer";

//Coupon
import Coupon from "../components/Coupon/template/Coupon";




const routes = [
    /* Auth Routes */
    { path: "/admin-login", name: "Login", component: Login },
	{ path: "/", redirect: { name: 'Home' } },
	{ path: "/home", name: "Home", component: Dashboard, beforeEnter: guard },
    { path: "/profile", name: "Profile", component: Profile, beforeEnter: guard },

    //Blog
    { path: "/blog-post", name: "BlogPost", component: BlogPost, beforeEnter: guard },
    { path: "/add-blog-post", name: "AddBlogPost", component: AddBlogPost, beforeEnter: guard },
    { path: "/update-blog-post/:id", name: "UpdateBlogPost", component: UpdateBlogPost, beforeEnter: guard },

    //Social Links
    { path: "/social-links", name: "SocialLinks", component: SocialLinks, beforeEnter: guard },
    //Instagram Section
    { path: "/instagram-section", name: "InstagramService", component: InstagramService, beforeEnter: guard },

    //Contact
    { path: "/contact-message", name: "ContactMessageList", component: ContactMessageList, beforeEnter: guard },


    //CMS
    { path: "/header-banner-cms", name: "HeaderBannerCMS", component: HeaderBannerCMS, beforeEnter: guard },
    { path: "/footer-cms", name: "FooterCMS", component: FooterCMS, beforeEnter: guard },
    { path: "/footer-payment-gateway", name: "FooterPayment", component: FooterPayment, beforeEnter: guard },
    { path: "/trending-section-cms", name: "TrendingSectionCMS", component: TrendingSectionCMS, beforeEnter: guard },
    { path: "/contact-us-cms", name: "ContactUsPageCMS", component: ContactUsPageCMS, beforeEnter: guard },
    { path: "/about-us-cms", name: "AboutUsPageCMS", component: AboutUsPageCMS, beforeEnter: guard },
    { path: "/blog-cms", name: "BlogPageCMS", component: BlogPageCMS, beforeEnter: guard },
    { path: "/faq", name: "Faq", component: Faq, beforeEnter: guard },
    { path: "/refund-page-cms", name: "RefundPageCMS", component: RefundPageCMS, beforeEnter: guard },
    { path: "/privacy-page-cms", name: "PrivacyPageCMS", component: PrivacyPageCMS, beforeEnter: guard },
    { path: "/hero-section-slider", name: "HeroSectionSlider", component: HeroSectionSlider, beforeEnter: guard },
    { path: "/collection-page", name: "CollectionPage", component: CollectionPage, beforeEnter: guard },
    { path: "/looks-you-will-love-cms", name: "LooksYouWillLoveCMS", component: LooksYouWillLoveCMS, beforeEnter: guard },
    { path: "/clearence-sale-section-cms", name: "ClearenceSaleSectionCMS", component: ClearenceSaleSectionCMS, beforeEnter: guard },
    { path: "/instagram-section-cms", name: "InstagramSectionCMS", component: InstagramSectionCMS, beforeEnter: guard },
    { path: "/latest-arrival-cms", name: "LatestArrivalCMS", component: LatestArrivalCMS, beforeEnter: guard },
    { path: "/latest-news-cms", name: "LatestNewsCMS", component: LatestNewsCMS, beforeEnter: guard },
    { path: "/latest-collection", name: "LatestCollection", component: LatestCollection, beforeEnter: guard },
    { path: "/product-details-page-cms", name: "ProductDetailsPageCMS", component: ProductDetailsPageCMS, beforeEnter: guard },
    { path: "/shop-page-cms", name: "ShopPageCms", component: ShopPageCms, beforeEnter: guard },
    { path: "/site-page-title-cms", name: "SitePageTitleCMS", component: SitePageTitleCMS, beforeEnter: guard },

    //About us
    { path: "/our-values", name: "OurValues", component: OurValues, beforeEnter: guard },
    
    //Products
    { path: "/add-product", name: "AddProduct", component: AddProduct, beforeEnter: guard },
    { path: "/update-product/:id", name: "UpdateProduct", component: UpdateProduct, beforeEnter: guard },
    { path: "/product-list", name: "ProductList", component: ProductList, beforeEnter: guard },
    { path: "/product-category", name: "ProductCategory", component: ProductCategory, beforeEnter: guard },
    { path: "/product-size", name: "ProductSize", component: ProductSize, beforeEnter: guard },
    { path: "/add-new-size-guide", name: "AddNewSizeGuide", component: AddNewSizeGuide, beforeEnter: guard },
    { path: '/edit-size-guide/:id', name: "EditSizeGuide", component: EditSizeGuide, beforeEnter: guard },
    { path: "/product-size-guide", name: "ProductSizeGuide", component: ProductSizeGuide, beforeEnter: guard },
    { path: "/product-sale", name: "ProductSale", component: ProductSale, beforeEnter: guard },
    { path: "/product-color", name: "ProductColor", component: ProductColor, beforeEnter: guard },
    { path: "/product-care", name: "ProductCare", component: ProductCare, beforeEnter: guard },
    { path: "/product-offer", name: "ProductOffer", component: ProductOffer, beforeEnter: guard },
    { path: "/product-perk", name: "ProductPerk", component: ProductPerk, beforeEnter: guard },
    { path: "/product-bulk-upload", name: "ProductBulkUpload", component: ProductBulkUpload, beforeEnter: guard },
    { path: "/stock-in-product", name: "ProductStockIn", component: ProductStockIn, beforeEnter: guard },

    //Order
    { path: "/create-order", name: "CreateOrder", component: CreateOrder, beforeEnter: guard },
    { path: "/order-details/:id", name: "OrderDetails", component: OrderDetails, beforeEnter: guard },
    { path: "/order-list", name: "OrderList", component: OrderList, beforeEnter: guard },

    //Payment
    { path: "/payment-configuration", name: "PaymentConfiguration", component: PaymentConfiguration, beforeEnter: guard },
    { path: "/payment-list", name: 'PaymentList', component: PaymentList, beforeEnter: guard },

    //Tax
    { path: "/sales-tax", name: 'SalesTax', component: SalesTax, beforeEnter: guard },

    //Shipping Settings
    { path: "/ups-settings", name: 'Ups', component: Ups, beforeEnter: guard },
    { path: "/fedex-settings", name: 'Fedex', component: Fedex, beforeEnter: guard },
    { path: "/shipping-methods", name: 'ShippingMethods', component: ShippingMethods, beforeEnter: guard },
    { path: "/shipping-cms", name: "ShippingCMS", component: ShippingCMS, beforeEnter: guard },

    //User
    { path: "/admins", name: "Admin", component: Admin, beforeEnter: guard },
    { path: "/customers", name: "Customer", component: Customer, beforeEnter: guard },

    //Coupon
    { path: "/coupon", name: "Coupon", component: Coupon, beforeEnter: guard },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

export default router;
