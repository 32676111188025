import { authHeader } from "../../../../auth";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
// import {Base64UploadAdapter} from '@ckeditor/ckeditor5-upload/src/adapters/base64uploadadapter';
export default {
    data() {
        return {
            editorData: '<p>Hello, world!</p>',
            editor: ClassicEditor,
            editorConfig: {
                ckfinder: {
                    // The URL of your file repository.
                    uploadUrl: this.$serverURL + this.$api.editor.uploadFileURL,
                }
            },
            name: '',
            featuredImage: "",
            shortDescription: '',
            longDescription: '',
            price: '',
            discountPrice: '',
            cost: '',
            outerShell:'',
            lining:'',
            sku: '',
            weight: '',
            stock: 1,
            isReadyToShip: false,
            isDeactive: false,
            parentCategory: "",
            color: [],
            colorsList: [],
            size: [],
            sizesList: [],
            sales: [],
            salesList: [],
            offers: [],
            offerssList: [],
            cares: [],
            caresList: [],
            perks: [],
            perksList: [],
            previewFeaturedImage: null,
            productImageList: [],
            previewProductImages: [],
            disableButton: false,
            setURL: this.$serverURL + this.$api.product.productURL,
            productList: [],
        }

    },
    async mounted() {
        await this.getSizeVarient();
        await this.getColorVarient();
        await this.getSaleVarient();
        await this.getOfferVarient();
        await this.getCareVarient();
        await this.getPerksVarient();
        await this.getAllParentCategories();
        await this.getDataList(this.setURL);
        // console.log(this.dataList[0]);
        this.productList = this.dataList.map(el => {
            return { value: el, label: el.name }
        })
    },
    methods: {
        uploadFeaturedImage: function () {
            this.featuredImage = event.target.files[0];
            let input = this.$refs.featuredImageInput
            let file = input.files
            if (file && file[0]) {
                let reader = new FileReader
                reader.onload = e => {
                    this.previewFeaturedImage = e.target.result
                }
                reader.readAsDataURL(file[0])
            }
        },
        uploadProductImages: function () {
            this.productImageList.push(this.$refs.productImages.files[0]);
            if (this.productImageList.length > 2) {
                this.disableButton = true;
            }
            let input = this.$refs.productImages
            let file = input.files
            if (file && file[0]) {
                let reader = new FileReader
                reader.onload = e => {
                    this.previewProductImages.push(e.target.result)
                }
                reader.readAsDataURL(file[0])
            }
            this.$refs.productImages.value = null;
        },
        removeUploadedProductImage: function (index) {
            this.previewProductImages.splice(index, 1);
            this.productImageList.splice(index, 1);
            if (this.productImageList.length > 3) {
                this.disableButton = true;
            } else {
                this.disableButton = false;
            }
        },
        getSizeVarient: async function () {
            let url = this.$serverURL + this.$api.product.productSize;
            let config = {
                method: "GET",
                url: url,
            }
            await this.$axios(config).then(async (response) => {
                if (response.status == 200) {
                    this.sizesList = response.data.data.map(el => {
                        return { value: el.name, label: el.name }
                    });
                }
                else {

                    this.$swal.fire({
                        icon: "error",
                        text: "Failed! please try again."
                    });
                }
            }).catch(error => {

                this.$swal.fire({
                    icon: "error",
                    text: "Failed! please try again." + error
                });
            });
        },
        getColorVarient: async function () {
            let url = this.$serverURL + this.$api.product.productColor;
            let config = {
                method: "GET",
                url: url,
                headers: {
                    "Authorization": authHeader()
                }
            }
            await this.$axios(config).then(async (response) => {
                if (response.status == 200) {
                    this.colorsList = response.data.data.map(el => {
                        return { value: el.name, label: el.name }
                    });
                }
                else {

                    this.$swal.fire({
                        icon: "error",
                        text: "Failed! please try again."
                    });
                }
            }).catch(error => {

                this.$swal.fire({
                    icon: "error",
                    text: "Failed! please try again." + error
                });
            });
        },
        getSaleVarient: async function () {
            let url = this.$serverURL + this.$api.product.productSale;
            let config = {
                method: "GET",
                url: url,
                headers: {
                    "Authorization": authHeader()
                }
            }
            await this.$axios(config).then(async (response) => {
                if (response.status == 200) {
                    this.salesList = response.data.data.map(el => {
                        return { value: el.name, label: el.name }
                    });
                }
                else {

                    this.$swal.fire({
                        icon: "error",
                        text: "Failed! please try again."
                    });
                }
            }).catch(error => {

                this.$swal.fire({
                    icon: "error",
                    text: "Failed! please try again." + error
                });
            });
        },
        getOfferVarient: async function () {
            let url = this.$serverURL + this.$api.product.productOffer;
            let config = {
                method: "GET",
                url: url,
                headers: {
                    "Authorization": authHeader()
                }
            }
            await this.$axios(config).then(async (response) => {
                if (response.status == 200) {
                    this.offersList = response.data.data.map(el => {
                        console.log(el);
                        return { value: el.title, label: el.title }
                    });
                }
                else {

                    this.$swal.fire({
                        icon: "error",
                        text: "Failed! please try again."
                    });
                }
            }).catch(error => {

                this.$swal.fire({
                    icon: "error",
                    text: "Failed! please try again." + error
                });
            });
        },
        getCareVarient: async function () {
            let url = this.$serverURL + this.$api.product.productCare;
            let config = {
                method: "GET",
                url: url,
                headers: {
                    "Authorization": authHeader()
                }
            }
            await this.$axios(config).then(async (response) => {
                if (response.status == 200) {
                    this.caresList = response.data.data.map(el => {
                        return { value: el.name, label: el.name }
                    });
                }
                else {

                    this.$swal.fire({
                        icon: "error",
                        text: "Failed! please try again."
                    });
                }
            }).catch(error => {

                this.$swal.fire({
                    icon: "error",
                    text: "Failed! please try again." + error
                });
            });
        },
        getPerksVarient: async function () {
            let url = this.$serverURL + this.$api.product.productPerk;
            let config = {
                method: "GET",
                url: url,
                headers: {
                    "Authorization": authHeader()
                }
            }
            await this.$axios(config).then(async (response) => {
                if (response.status == 200) {
                    this.perksList = response.data.data.map(el => {
                        return { value: el.title, label: el.title }
                    });
                }
                else {

                    this.$swal.fire({
                        icon: "error",
                        text: "Failed! please try again."
                    });
                }
            }).catch(error => {

                this.$swal.fire({
                    icon: "error",
                    text: "Failed! please try again." + error
                });
            });
        },
        getCategoryList: async function (id) {
            this.categoryList = [];
            this.category = "";
            this.categoryList.push({ id: '', name: 'No Category' });
            let url = this.$serverURL + this.$api.product.getAllCategories + '?parentCategoryId=' + id;
            let config = {
                method: "GET",
                url: url,
                headers: {
                    "Authorization": authHeader()
                }
            }
            await this.$axios(config).then(async (response) => {
                if (response.status == 200) {

                    this.subCategoryList = response.data.data;
                    this.subCategoryList.push({ id: '', name: 'No Sub-Category' });

                }
                else {

                    this.$swal.fire({
                        icon: "error",
                        text: "Failed! please try again."
                    });
                }
            }).catch(error => {

                this.$swal.fire({
                    icon: "error",
                    text: "Failed! please try again." + error
                });
            });
        },
        checkValidation: function () {
            if (!this.name) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter product name!"
                });
                return false;
            }
            else if (!this.shortDescription) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter product short description!"
                });
                return false;
            }
            else if (!this.longDescription) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter product long description!"
                });
                return false;
            }
            else if (!this.parentCategory.id) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter category!"
                });
                return false;
            } else if (!this.featuredImage) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter featured image!"
                });
                return false;
            } else if (this.productImageList.length == 0) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter product image!"
                });
                return false;
            } else if (!this.price) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter product price!"
                });
                return false;
            } else if (!this.cost) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter product cost!"
                });
                return false;
            } else if (!this.weight) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter product weight!"
                });
                return false;
            }
            else {
                return true;
            }

        },
        addProduct: async function () {
            this.$swal.showLoading();
            if (this.checkValidation()) {

                let formData = new FormData();
                formData.append("name", this.name);
                formData.append("shortDescription", this.shortDescription);
                formData.append("longDescription", this.longDescription);
                formData.append("parentCategoryId", this.parentCategory.id);
                formData.append("parentCategoryName", this.parentCategory.name);
                formData.append("featuredImage", this.featuredImage);
                formData.append("price", this.price);
                formData.append("discountPrice", this.discountPrice);
                formData.append("cost", this.cost);
                formData.append("stock", this.stock);
                formData.append("outerShell", this.outerShell);
                formData.append("lining", this.lining);
                formData.append("sku", this.sku);
                formData.append("weight", this.weight);
                console.log(this.perks);
                this.productImageList.map((el) => {
                    formData.append("productImages", el);
                })
                this.color.map((el) => {
                    formData.append("color[]", el);
                });
                this.size.map((el) => {
                    formData.append("size[]", el);
                });
                this.sales.map((el) => {
                    formData.append("sales[]", el);
                });
                this.offers.map((el) => {
                    formData.append("offers[]", el);
                });
                this.cares.map((el) => {
                    formData.append("cares[]", el);
                });
                this.perks.map((el) => {
                    formData.append("perks[]", el);
                });

                formData.append("isReadyToShip", this.isReadyToShip ? 1 : 0);
                formData.append("isDeactive", this.isDeactive ? 1 : 0);

                let config = {
                    method: "POST",
                    data: formData,
                    url: this.setURL,
                    headers: {
                        "Authorization": authHeader()
                    }
                }
                await this.$axios(config).then(async (response) => {
                    this.$swal.hideLoading();
                    if (response.status == 201) {
                        this.$swal.fire({
                            toast: true,
                            position: 'top-end',
                            text: response.data.message,
                            icon: "success",
                            showConfirmButton: false,
                            timer: 3000,
                            animation: false,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                                toast.addEventListener('mouseenter', this.$swal.stopTimer)
                                toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                            }
                        });
                        this.$router.push("/product-list");
                    }
                    else {

                        this.$swal.fire({
                            icon: "error",
                            text: "Failed! please try again."
                        });
                    }
                }).catch(error => {
                    if (error.response.data) {
                        this.$swal.fire({
                            icon: "error",
                            text: error.response.data.message
                        });
                    }
                    else {
                        this.$swal.fire({
                            icon: "error",
                            text: "Failed! please try again."
                        });
                    }

                });
            }
        }
    },
}