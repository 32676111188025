import { authHeader } from "../../../../auth";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
export default {
    data() {
        return {
            editor: ClassicEditor,
            editorConfig: {      
                ckfinder: {
                    // The URL of your file repository.
                    uploadUrl: this.$serverURL + this.$api.editor.uploadFileURL,
                }
            },
            featuredImage: "",
            data: [],
            color: [],
            colorsList: [],
            size: [],
            sizesList: [],
            sales: [],
            salesList: [],
            offers: [],
            offerssList: [],
            cares: [],
            caresList: [],
            perks: [],
            perksList: [],
            previewFeaturedImage: null,
            productImageList: [],
            previewProductImages: [],
            disableButton: false,
            id: this.$route.params.id,
            siteUrl: this.$serverURL + this.$api.product.productURL,
            imageUrl: this.$imageURL,
            singleProduct: {},
            parentCategory: "",
            subCategoryList: [],
            deletedImage: [],
            shortDescription: "",
            longDescription: "",
            // productList:[]
        }

    },
    async mounted() {
        await this.getSizeVarient();
        await this.getColorVarient();
        await this.getSaleVarient();
        await this.getOfferVarient();
        await this.getCareVarient();
        await this.getPerksVarient();
        await this.getProductDetails();
        await this.getAllParentCategories();
        // await this.getDataList(this.siteUrl);
        // this.productList = this.dataList.map(el => {
        //     return {value: {id:el.id, name: el.name}, label: el.name}
        // })
    },
    methods: {
        checkName(data) {
            if (data.startsWith("https")) {
                return true;
            } else {
                return false;
            }
        },
        getProductDetails: async function () {
            try {
                let config = {
                    method: "GET",
                    url: this.siteUrl + '/' + this.id,
                    headers: {
                        "Authorization": authHeader()
                    }
                };
                this.$axios(config).then(async (response) => {
                    if (response.data.statusCode == 200) {
                        if (response.data?.data) {
                            this.singleProduct = response.data.data;
                            if (this.singleProduct.name) {
                                this.name = this.singleProduct.name;
                            }
                            if (this.singleProduct.shortDescription) {
                                this.shortDescription = this.singleProduct.shortDescription;
                            }
                            if (this.singleProduct.longDescription) {
                                this.longDescription = this.singleProduct.longDescription;
                            }
                            if (this.singleProduct.parentCategoryId) {
                                await this.getCategoryList(this.singleProduct.parentCategoryId);
                                //this.parentCategory = { name: this.singleProduct.parentCategoryName, id: this.singleProduct.parentCategoryId }
                            }
                            this.productImageList = this.singleProduct.imageGallery.map(el => {
                                return el.image;
                            });
                            this.previewProductImages = this.singleProduct.imageGallery.map(el => {
                                return el.image;
                            });
                            this.color = this.singleProduct.colorVariants.map(el => {
                                return el.colorName;
                            });
                            this.size = this.singleProduct.sizeVariants.map(el => {
                                return el.sizeName;
                            });
                            this.sales = this.singleProduct.salesVariants.map(el => {
                                return el.saleName;
                            });
                            this.offers = this.singleProduct.offerVariants.map(el => {
                                return el.offerName;
                            });
                            this.cares = this.singleProduct.careVariants.map(el => {
                                return el.careName;
                            });
                            
                            this.perks = this.singleProduct.perksVariants.map(el => {
                                return el.perkTitle;
                            });
                            
                        }
                    }
                }).catch(error => {
                    console.log(error.response.data.message);
                });

            } catch (error) {
                console.log(error);
            }
        },
        uploadFeaturedImage() {
            this.featuredImage = event.target.files[0];
            let input = this.$refs.featuredImageInput
            let file = input.files
            if (file && file[0]) {
                let reader = new FileReader
                reader.onload = e => {
                    this.previewFeaturedImage = e.target.result
                }
                reader.readAsDataURL(file[0])
            }
        },
        uploadProductImages() {
            this.productImageList.push(this.$refs.productImages.files[0]);
            if (this.productImageList.length > 2) {
                this.disableButton = true;
            }
            let input = this.$refs.productImages
            let file = input.files
            if (file && file[0]) {
                let reader = new FileReader
                reader.onload = e => {
                    this.previewProductImages.push(e.target.result)
                }
                reader.readAsDataURL(file[0])
            }
            this.$refs.productImages.value = null;
        },
        getSizeVarient: async function () {
            let url = this.$serverURL + this.$api.product.productSize;
            let config = {
                method: "GET",
                url: url,
                headers: {
                    "Authorization": authHeader()
                }
            }
            await this.$axios(config).then(async (response) => {
                if (response.status == 200) {
                    this.sizesList = response.data.data.map(el => {
                        return { value: el.name, label: el.name }
                    });
                }
                else {

                    this.$swal.fire({
                        icon: "error",
                        text: "Failed! please try again."
                    });
                }
            }).catch(error => {

                this.$swal.fire({
                    icon: "error",
                    text: "Failed! please try again." + error
                });
            });
        },
        getColorVarient: async function () {
            let url = this.$serverURL + this.$api.product.productColor;
            let config = {
                method: "GET",
                url: url,
                headers: {
                    "Authorization": authHeader()
                }
            }
            await this.$axios(config).then(async (response) => {
                if (response.status == 200) {
                    this.colorsList = response.data.data.map(el => {
                        return { value: el.name, label: el.name }
                    });
                }
                else {

                    this.$swal.fire({
                        icon: "error",
                        text: "Failed! please try again."
                    });
                }
            }).catch(error => {

                this.$swal.fire({
                    icon: "error",
                    text: "Failed! please try again." + error
                });
            });
        },
        getSaleVarient: async function () {
            let url = this.$serverURL + this.$api.product.productSale;
            let config = {
                method: "GET",
                url: url,
                headers: {
                    "Authorization": authHeader()
                }
            }
            await this.$axios(config).then(async (response) => {
                if (response.status == 200) {
                    this.salesList = response.data.data.map(el => {
                        return { value: el.name, label: el.name }
                    });
                }
                else {

                    this.$swal.fire({
                        icon: "error",
                        text: "Failed! please try again."
                    });
                }
            }).catch(error => {

                this.$swal.fire({
                    icon: "error",
                    text: "Failed! please try again." + error
                });
            });
        },
        getOfferVarient: async function () {
            let url = this.$serverURL + this.$api.product.productOffer;
            let config = {
                method: "GET",
                url: url,
                headers: {
                    "Authorization": authHeader()
                }
            }
            await this.$axios(config).then(async (response) => {
                if (response.status == 200) {
                    this.offersList = response.data.data.map(el => {
                        return { value: el.title, label: el.title }
                    });
                }
                else {

                    this.$swal.fire({
                        icon: "error",
                        text: "Failed! please try again."
                    });
                }
            }).catch(error => {

                this.$swal.fire({
                    icon: "error",
                    text: "Failed! please try again." + error
                });
            });
        },
        getCareVarient: async function () {
            let url = this.$serverURL + this.$api.product.productCare;
            let config = {
                method: "GET",
                url: url,
                headers: {
                    "Authorization": authHeader()
                }
            }
            await this.$axios(config).then(async (response) => {
                if (response.status == 200) {
                    this.caresList = response.data.data.map(el => {
                        return { value: el.name, label: el.name }
                    });
                }
                else {

                    this.$swal.fire({
                        icon: "error",
                        text: "Failed! please try again."
                    });
                }
            }).catch(error => {

                this.$swal.fire({
                    icon: "error",
                    text: "Failed! please try again." + error
                });
            });
        },
        getPerksVarient: async function () {
            let url = this.$serverURL + this.$api.product.productPerk;
            let config = {
                method: "GET",
                url: url,
                headers: {
                    "Authorization": authHeader()
                }
            }
            await this.$axios(config).then(async (response) => {
                if (response.status == 200) {
                    this.perksList = response.data.data.map(el => {
                        return { value: el.title, label: el.title }
                    });
                }
                else {

                    this.$swal.fire({
                        icon: "error",
                        text: "Failed! please try again."
                    });
                }
            }).catch(error => {

                this.$swal.fire({
                    icon: "error",
                    text: "Failed! please try again." + error
                });
            });
        },
        getCategoryList: async function (id) {
            this.category = "";
            this.categoryList = [];
            this.categoryList.push({ id: '', name: 'No Category' });
            let url = this.$serverURL + this.$api.product.getAllCategories + '?parentCategoryId=' + id;
            let config = {
                method: "GET",
                url: url,
                headers: {
                    "Authorization": authHeader()
                }
            }
            await this.$axios(config).then(async (response) => {
                if (response.status == 200) {
                    this.subCategoryList = response.data.data;
                    this.subCategoryList.push({ id: '', name: 'No Sub-Category' });
                    if (this.subCategoryList.length > 0) {
                        this.showSubCategory = true;
                    } else {
                        this.showSubCategory = false;
                    }
                }
                else {

                    this.$swal.fire({
                        icon: "error",
                        text: "Failed! please try again."
                    });
                }
            }).catch(error => {

                this.$swal.fire({
                    icon: "error",
                    text: "Failed! please try again." + error
                });
            });
        },
        validate: function () {
            if (!this.singleProduct.name) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter product name!"
                });
                return false;
            }
            if (!this.parentCategory && !this.singleProduct.parentCategoryName) {
                this.$swal.fire({
                    icon: "error",
                    text: "Select category."
                });
                return;
            } 
            else if (!this.singleProduct.featuredImage && !this.featuredImage) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter featured image!"
                });
                return false;
            } else if (!this.singleProduct.price) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter product price!"
                });
                return false;
            } 
            else if (!this.singleProduct.cost) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter product cost!"
                });
                return false;
            }
            else if (!this.singleProduct.weight) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter product weight!"
                });
                return false;
            }
            return true;
        },
        updateProduct: async function () {
            this.$swal.showLoading();
            if (this.validate()) {
                let formData = new FormData();
                formData.append("name", this.singleProduct.name);
                formData.append("shortDescription", this.shortDescription);
                formData.append("longDescription", this.longDescription);
                formData.append("price", this.singleProduct.price);
                formData.append("discountPrice", this.singleProduct.discountPrice);
                formData.append("stock", this.singleProduct.stock);
                formData.append("cost", this.singleProduct.cost);
                formData.append("sku", this.singleProduct.sku);
                formData.append("weight", this.singleProduct.weight);
                formData.append("outerShell", this.singleProduct.outerShell);
                formData.append("lining", this.singleProduct.lining);
                formData.append("isDeactive", this.singleProduct.isDeactive ? 1 : 0);
                formData.append("isReadyToShip", this.singleProduct.isReadyToShip ? 1 : 0);
                if (this.parentCategory) {
                    formData.append("parentCategoryId", this.parentCategory.id);
                    formData.append("parentCategoryName", this.parentCategory.name);
                } else {
                    formData.append("parentCategoryId", this.singleProduct.parentCategoryId);
                    formData.append("parentCategoryName", this.singleProduct.parentCategoryName);
                }
                if (this.featuredImage) {
                    formData.append("featuredImage", this.featuredImage);
                } else {
                    formData.append("featuredImage", this.singleProduct.featuredImage);
                }
                this.productImageList.map((el) => {
                    formData.append("productImages", el);
                });
                this.deletedImage.map((el) => {
                    formData.append("deletedImage", el);
                });
                this.color.map((el) => {
                    formData.append("color[]", el);
                });
                this.size.map((el) => {
                    formData.append("size[]", el);
                });
                this.sales.map((el) => {
                    formData.append("sales[]", el);
                });
                this.offers.map((el) => {
                    formData.append("offers[]", el);
                });
                this.cares.map((el) => {
                    formData.append("cares[]", el);
                });
                this.perks.map((el) => {
                    formData.append("perks[]", el);
                });
                let config = {
                    method: "PATCH",
                    data: formData,
                    url: this.siteUrl + '/' + this.id,
                    headers: {
                        "Authorization": authHeader()
                    }
                }
                await this.$axios(config).then(async (response) => {
                    this.$swal.hideLoading();
                    if (response.status == 200) {
                        this.$swal.fire({
                            toast: true,
                            position: 'top-end',
                            text: response.data.message,
                            icon: "success",
                            showConfirmButton: false,
                            timer: 3000,
                            animation: false,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                                toast.addEventListener('mouseenter', this.$swal.stopTimer)
                                toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                            }
                        });
                        this.$router.push("/product-list");
                    }
                    else {

                        this.$swal.fire({
                            icon: "error",
                            text: "Failed! please try again."
                        });
                    }
                }).catch(error => {

                    this.$swal.fire({
                        icon: "error",
                        text: "Failed! please try again." + error
                    });
                });
            }
        },
        removeUploadedProductImage(index) {
            this.previewProductImages.splice(index, 1);
            if (typeof this.productImageList[index] === "string") {
                if (this.productImageList[index].startsWith("https")) {
                    this.deletedImage.push(this.productImageList[index]);
                }
            }
            this.productImageList.splice(index, 1);
            if (this.productImageList.length > 3) {
                this.disableButton = true;
            } else {
                this.disableButton = false;
            }
        }
    }
}