export default {
    data() {
        return {
            url: this.$serverURL + this.$api.cms.sitePageTitleCMS,
            cmsData: {
                // id: "",
                signinPageTitle: "",
                signupPageTitle: "",
                homePageTitle: "",
                aboutUsPageTitle: "",
                shopPageTitle: "",
                productDetailsPageTitle: "",
                wishlistPageTitle: "",
                cartPageTitle: "",
                salesPageTitle: "",
                contactUsPageTitle: "",
                collectionPageTitle: "",
                userPageTitle: "",
                checkoutPageTitle: "",
                blogPageTitle: "",
                blogDetailsPageTitle: "",
                forgotPasswordPageTitle: "",
                resetPasswordPageTitle: "",
                termsPageTitle: "",
                privacyPageTitle: ""
            }
        }
    },
    async created() {
        document.title = "Hyman Fashion - Site Page Title CMS";
        this.getData();
    },
    methods: {
        async getData() {
            try {
                let response = await this.getCmsData(this.url);
                if (response.status == 200) {
                    this.cmsData = (response.data.data.length > 0) ? response.data.data[0] : {};
                }
                else {
                    this.cmsData = {};
                }
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Failed! please try again." + error
                });
            }
        },
        async update() {
            try {
                let data = {
                    // id: this.cmsData?.id,
                    signinPageTitle: this.cmsData?.signinPageTitle,
                    signupPageTitle: this.cmsData?.signupPageTitle,
                    homePageTitle: this.cmsData?.homePageTitle,
                    aboutUsPageTitle: this.cmsData?.aboutUsPageTitle,
                    shopPageTitle: this.cmsData?.shopPageTitle,
                    productDetailsPageTitle: this.cmsData?.productDetailsPageTitle,
                    wishlistPageTitle: this.cmsData?.wishlistPageTitle,
                    cartPageTitle: this.cmsData?.cartPageTitle,
                    salesPageTitle: this.cmsData?.salesPageTitle,
                    contactUsPageTitle: this.cmsData?.contactUsPageTitle,
                    collectionPageTitle: this.cmsData?.collectionPageTitle,
                    userPageTitle: this.cmsData?.userPageTitle,
                    checkoutPageTitle: this.cmsData?.checkoutPageTitle,
                    blogPageTitle: this.cmsData?.blogPageTitle,
                    blogDetailsPageTitle: this.cmsData?.blogDetailsPageTitle,
                    forgotPasswordPageTitle: this.cmsData?.forgotPasswordPageTitle,
                    resetPasswordPageTitle: this.cmsData?.resetPasswordPageTitle,
                    termsPageTitle: this.cmsData?.termsPageTitle,
                    privacyPageTitle: this.cmsData?.privacyPageTitle,
                };
                this.$swal.showLoading();
                let response = await this.createUpdateCMS(this.url, data);
                if (response.statusCode == 200 || response.statusCode == 201) {
                    this.$swal.fire({
                        toast: true,
                        position: 'top-end',
                        text: response.message,
                        icon: "success",
                        showConfirmButton: false,
                        timer: 3000,
                        animation: false,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                        }
                    });
                }
                await this.getData();
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            } finally {
                this.$swal.hideLoading();
            }
        }
    }
}