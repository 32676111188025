<template>
    <div class="page-wrapper">
        <div class="page-content">
            <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                <div class="breadcrumb-title pe-3">About Page</div>
                <div class="ps-3">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb mb-0 p-0">
                            <li class="breadcrumb-item"><router-link to="/home"><i class="bx bx-home-alt"></i></router-link></li>
                            <li class="breadcrumb-item active" aria-current="page">About Page CMS</li>
                        </ol>
                    </nav>
                </div>
            </div>
            <div class="card">
                <div class="card-body">
                    <h5 class="card-title">About Page CMS</h5>
                    <hr/>
                    <div class="form-body mt-4">
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="mb-3">
                                    <label class="form-label">Page Title:</label>
                                    <input type="text" v-model="cmsData.pageTitle" class="form-control">
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="mb-3">
                                    <label class="form-label">Section Title:</label>
                                    <input type="text" v-model="cmsData.sectionTitle" class="form-control">
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="mb-3">
                                    <label class="form-label">Section Sub Title:</label>
                                    <textarea type="text" v-model="cmsData.sectionSubTitle" class="form-control" rows="5"></textarea>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="mb-3">
                                    <div class="row">
                                        <div class="col-12">
                                            <label class="form-label">
                                            Image:
                                            </label>
                                        </div>
                                        <div class="col-12">
                                            <img
                                            style="position: relative; left: 11px"
                                            v-if="preview.image"
                                            :src="preview.image"
                                            height="70"
                                            />
                                            <img
                                            style="position: relative; left: 11px"
                                            v-else-if="cmsData.image"
                                            :src="cmsData.image"
                                            height="70"
                                            />
                                        </div>
                                    </div>
                                    <input
                                    class="form-control mt-3"
                                    type="file"
                                    ref="image"
                                    accept="image/*"
                                    @change="handleUploadImage('image','image','image', $event)"
                                    />
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="mb-3">
                                    <label class="form-label">Achievement Title One:</label>
                                    <input type="text" v-model="cmsData.achievementTitleOne" class="form-control">
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="mb-3">
                                    <label class="form-label">Achievement Title One Value:</label>
                                    <input type="text" v-model="cmsData.achievementNumberOne" class="form-control">
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="mb-3">
                                    <label class="form-label">Achievement Title Two:</label>
                                    <input type="text" v-model="cmsData.achievementTitleTwo" class="form-control">
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="mb-3">
                                    <label class="form-label">Achievement Title Two Value:</label>
                                    <input type="text" v-model="cmsData.achievementNumberTwo" class="form-control">
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="mb-3">
                                    <label class="form-label">Achievement Title Three:</label>
                                    <input type="text" v-model="cmsData.achievementTitleThree" class="form-control">
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="mb-3">
                                    <label class="form-label">Achievement Title Three Value:</label>
                                    <input type="text" v-model="cmsData.achievementNumberThree" class="form-control">
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="mb-3">
                                    <label class="form-label">Achievement Title Four:</label>
                                    <input type="text" v-model="cmsData.achievementTitleFour" class="form-control">
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="mb-3">
                                    <label class="form-label">Achievement Title Four Value:</label>
                                    <input type="text" v-model="cmsData.achievementNumberFour" class="form-control">
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="mb-3">
                                    <label class="form-label">Value Section Title:</label>
                                    <input type="text" v-model="cmsData.valueSectionTitle" class="form-control">
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="mb-3">
                                    <label class="form-label">Value Section Sub Title:</label>
                                    <input type="text" v-model="cmsData.valueSectionSubTitle" class="form-control">
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="mb-3">
                                    <label class="form-label">Faq Section Title:</label>
                                    <input type="text" v-model="cmsData.faqSectionTitle" class="form-control">
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="mb-3">
                                    <label class="form-label">Faq Section Sub Title:</label>
                                    <input type="text" v-model="cmsData.faqSectionSubTitle" class="form-control">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="d-grid">
                                    <button v-on:click="update()" type="button" class="btn btn-primary">Save</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script src="../js/about-page-cms.js"></script>