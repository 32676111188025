<template>
  <div class="sidebar-wrapper" data-simplebar="true">
    <div class="sidebar-header">
      <div>
        <img src="../../../../public/core/assets/images/hyman-icon.png" class="logo-icon" alt="logo icon" />
      </div>
      <div>
        <h4 class="logo-text" style="font-size: 20px;">Hyman Fashion</h4>
      </div>
      <div class="toggle-icon ms-auto">
        <i class="bx bx-arrow-to-left"></i>
      </div>
    </div>
    <ul class="metismenu" id="menu">
      <li>
        <a href="javascript:void(0)" @click="$router.push('/home')">
          <div class="parent-icon">
            <i class='bx bxs-dashboard'></i>
          </div>
          <div class="menu-title">Dashboard</div>
        </a>
      </li>
      <li>
        <a href="javascript:void(0)" @click="$router.push('/contact-message')">
          <div class="parent-icon">
            <i class='bx bxs-message'></i>
          </div>
          <div class="menu-title">Contact</div>
        </a>
      </li>
      <li>
        <a href="javascript:void(0)" @click="$router.push('/coupon')">
          <div class="parent-icon">
            <i class='bx bxs-coupon'></i>
          </div>
          <div class="menu-title">Coupon</div>
        </a>
      </li>
      <li>
        <a href="#" class="has-arrow">
          <div class="parent-icon">
            <i class='bx bxs-user'></i>
          </div>
          <div class="menu-title">User</div>
        </a>
        <ul>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/admins')">
              <i class="bx bx-right-arrow-alt"></i>Admins
            </a>
          </li>
          <!-- <li>
            <a href="javascript:void(0)" @click="$router.push('/seo-engineer')">
              <i class="bx bx-right-arrow-alt"></i>SEO Engineer
            </a>
          </li> -->
          <li>
            <a href="javascript:void(0)" @click="$router.push('/customers')">
              <i class="bx bx-right-arrow-alt"></i>Customers
            </a>
          </li>
        </ul>
      </li>
      <li>
        <a class="has-arrow" href="javascript:void(0)">
          <div class="parent-icon">
            <i class='bx bxs-package' ></i>
          </div>
          <div class="menu-title">Products</div>
        </a>
        <ul>
          <li>
            <a href="javascript:void(0);" @click="$router.push('/product-list')">
              <i class="bx bx-right-arrow-alt"></i>Products
            </a>
          </li>
          <li>
            <a href="javascript:void(0);" @click="$router.push('/product-category')">
              <i class="bx bx-right-arrow-alt"></i>Product Category
            </a>
          </li>
          <li>
            <a href="javascript:void(0);" @click="$router.push('/product-size')">
              <i class="bx bx-right-arrow-alt"></i>Product Size
            </a>
          </li>
          <li>
            <a href="javascript:void(0);" @click="$router.push('/product-size-guide')">
              <i class="bx bx-right-arrow-alt"></i>Product Size Guide
            </a>
          </li>
          <li>
            <a href="javascript:void(0);" @click="$router.push('/product-color')">
              <i class="bx bx-right-arrow-alt"></i>Product Color
            </a>
          </li>
          <li>
            <a href="javascript:void(0);" @click="$router.push('/product-sale')">
              <i class="bx bx-right-arrow-alt"></i>Product Sale
            </a>
          </li>
          <li>
            <a href="javascript:void(0);" @click="$router.push('/product-care')">
              <i class="bx bx-right-arrow-alt"></i>Product Care
            </a>
          </li>
          <li>
            <a href="javascript:void(0);" @click="$router.push('/product-offer')">
              <i class="bx bx-right-arrow-alt"></i>Product Offer
            </a>
          </li>
          <li>
            <a href="javascript:void(0);" @click="$router.push('/product-perk')">
              <i class="bx bx-right-arrow-alt"></i>Product Perk
            </a>
          </li>
        </ul>
      </li>
      <li>
        <a class="has-arrow" href="javascript:void(0)">
          <div class="parent-icon">
            <i class='bx bxs-cart' ></i>
          </div>
          <div class="menu-title">Order</div>
        </a>
        <ul>
          <li>
            <a href="javascript:void(0);" @click="$router.push('/create-order')">
              <i class="bx bx-right-arrow-alt"></i>Create Order
            </a>
          </li>
          <li>
            <a href="javascript:void(0);" @click="$router.push('/order-list')">
              <i class="bx bx-right-arrow-alt"></i>Order List
            </a>
          </li>
        </ul>
      </li>
      
      <li>
        <a class="has-arrow" href="javascript:void(0)">
          <div class="parent-icon">
            <i class='bx bxs-credit-card' ></i>
          </div>
          <div class="menu-title">Payment</div>
        </a>
        <ul>
          <li>
            <a href="javascript:void(0);" @click="$router.push('/payment-configuration')">
              <i class="bx bx-right-arrow-alt"></i>Payment Credential
            </a>
          </li>
          <li>
            <a href="javascript:void(0);" @click="$router.push('/payment-list')">
              <i class="bx bx-right-arrow-alt"></i>Payment List
            </a>
          </li>
        </ul>
      </li>
      <li>
        <a class="has-arrow" href="javascript:void(0)">
          <div class="parent-icon">
            <i class='bx bxs-folder-minus'></i>
          </div>
          <div class="menu-title">Tax</div>
        </a>
        <ul>
          <li>
            <a href="javascript:void(0);" @click="$router.push('/sales-tax')">
              <i class="bx bx-right-arrow-alt"></i>Sales Tax
            </a>
          </li>
          <!-- <li>
            <a href="javascript:void(0);" @click="$router.push('/payment-list')">
              <i class="bx bx-right-arrow-alt"></i>Sales Tax Report
            </a>
          </li> -->
        </ul>
      </li>
      <li>
        <a class="has-arrow" href="javascript:void(0)">
          <div class="parent-icon">
            <i class='bx bxs-truck'></i>
          </div>
          <div class="menu-title">Shipping Settings</div>
        </a>
        <ul>
          <!-- <li>
            <a href="javascript:void(0);" @click="$router.push('/fedex-settings')">
              <i class="bx bx-right-arrow-alt"></i>FedEx Settings
            </a>
          </li> -->
          <li>
            <a href="javascript:void(0);" @click="$router.push('/ups-settings')">
              <i class="bx bx-right-arrow-alt"></i>Ups Settings
            </a>
          </li>
          <!-- <li>
            <a href="javascript:void(0);" @click="$router.push('/shipping-methods')">
              <i class="bx bx-right-arrow-alt"></i>Shipping Methods
            </a>
          </li> -->
          <li>
            <a href="javascript:void(0);" @click="$router.push('/shipping-cms')">
              <i class="bx bx-right-arrow-alt"></i>Shipping CMS
            </a>
          </li>
        </ul>
      </li>
      <li>
        <a class="has-arrow" href="javascript:void(0)">
          <div class="parent-icon">
            <i class="fa-solid fa-blog"></i>
          </div>
          <div class="menu-title">Blog</div>
        </a>
        <ul>
          <li>
            <a href="javascript:void(0);" @click="$router.push('/blog-post')">
              <i class="bx bx-right-arrow-alt"></i>Blog Post
            </a>
          </li>
        </ul>
      </li>
      <li>
        <a class="has-arrow" href="javascript:void(0)">
          <div class="parent-icon">
            <i class='bx bxs-cog' ></i>
          </div>
          <div class="menu-title">CMS Settings</div>
        </a>
        <ul>
          <li>
            <a class="has-arrow" href="javascript:void(0)">
              <div class="parent-icon">
                <i class='bx bx-home-alt' ></i>
              </div>
              Home
            </a>
            <ul>
              <li>
                <a href="/hero-section" @click.prevent="$router.push('/hero-section-slider')">
                  <i class="bx bx-right-arrow-alt"></i>Hero Section Slider
                </a>
              </li>
              <li>
                <a href="/trending-section-cms" @click.prevent="$router.push('/trending-section-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Trending Section CMS
                </a>
              </li>
              <li>
                <a href="/hero-section" @click.prevent="$router.push('/latest-arrival-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Latest Arrival CMS
                </a>
              </li>
              <li>
                <a href="/hero-section" @click.prevent="$router.push('/latest-collection')">
                  <i class="bx bx-right-arrow-alt"></i>Latest Collection
                </a>
              </li>
              <li>
                <a class="has-arrow" href="javascript:void(0)">
                  <i class='bx bx-heart'></i>
                  Looks You Will Love
                </a>
                <ul>
                  <li>
                    <a href="/looks-you-will-love-cms" @click.prevent="$router.push('/looks-you-will-love-cms')">
                      <i class="bx bx-right-arrow-alt"></i>Looks You Will Love CMS
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                <a class="has-arrow" href="javascript:void(0)">
                  <i class='bx bx-wallet-alt' ></i>Clearence Sale Section
                </a>
                <ul>
                  <li>
                    <a href="/clearence-sale-section-cms" @click.prevent="$router.push('/clearence-sale-section-cms')">
                      <i class="bx bx-right-arrow-alt"></i>Clearence Sale Section CMS
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                <a href="/hero-section" @click.prevent="$router.push('/latest-news-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Latest News CMS
                </a>
              </li>
              <li>
                <a class="has-arrow" href="javascript:void(0)">
                  <i class='bx bxl-instagram' ></i>Instagram Section
                </a>
                <ul>
                  <li>
                    <a href="/instagram-section-cms" @click.prevent="$router.push('/instagram-section-cms')">
                      <i class="bx bx-right-arrow-alt"></i>Instagram Section CMS
                    </a>
                  </li>
                  <li>
                    <a href="/instagram-section" @click.prevent="$router.push('/instagram-section')">
                      <i class="bx bx-right-arrow-alt"></i>Instagram Section
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li>
            <a class="has-arrow" href="javascript:void(0)">
              <div class="parent-icon">
                <i class='bx bx-copy-alt'></i>
              </div>
              Header Footer
            </a>
            <ul>
              <li>
                <a href="/header-banner-cms" @click.prevent="$router.push('/header-banner-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Header Banner CMS
                </a>
              </li>
              <li>
                <a href="/footer-cms" @click.prevent="$router.push('/footer-cms')">
                  <i class="bx bx-right-arrow-alt"></i>Footer CMS
                </a>
              </li>
              <li>
                <a href="/footer-cms" @click.prevent="$router.push('/footer-payment-gateway')">
                  <i class="bx bx-right-arrow-alt"></i>Footer Payment Gateway
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a class="has-arrow" href="javascript:void(0)">
              <div class="parent-icon">
                <i class="fa-solid fa-question"></i>
              </div>
               FAQ
            </a>
            <ul>
              <li>
                <a href="/footer-cms" @click.prevent="$router.push('/faq')">
                  <i class="bx bx-right-arrow-alt"></i>Q & A
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a class="has-arrow" href="javascript:void(0)">
              <div class="parent-icon">
                <i class='bx bx-error-circle' ></i>
              </div>
              About Us Page
            </a>
            <ul>
              <li>
                <a href="/about-us-cms" @click.prevent="$router.push('/about-us-cms')">
                  <i class="bx bx-right-arrow-alt"></i>About Us CMS
                </a>
              </li>
              <li>
                <a href="/our-values" @click.prevent="$router.push('/our-values')">
                  <i class="bx bx-right-arrow-alt"></i>Our Values Section
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a class="has-arrow" href="javascript:void(0)">
              <div class="parent-icon">
                <i class='bx bx-collection'></i>
              </div>
              Collection Page
            </a>
            <ul>
              <li>
                <a href="/about-us-cms" @click.prevent="$router.push('/collection-page')">
                  <i class="bx bx-right-arrow-alt"></i>Collection Page
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/site-page-title-cms')">
              <i class="bx bx-right-arrow-alt"></i>
              Site Page Title CMS
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/shop-page-cms')">
              <i class="bx bx-right-arrow-alt"></i>
              Shop Page CMS
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="$router.push('/product-details-page-cms')">
              <i class="bx bx-right-arrow-alt"></i>
              Product Details Page CMS
            </a>
          </li>
          <li>
            <a href="/contact-us-cms" @click.prevent="$router.push('/contact-us-cms')">
              <i class="bx bx-right-arrow-alt"></i>Contact Us Page CMS
            </a>
          </li>
          <li>
            <a href="/blog-cms" @click.prevent="$router.push('/blog-cms')">
              <i class="bx bx-right-arrow-alt"></i>Blog Page CMS
            </a>
          </li>
          <li>
            <a href="/refund-page-cms" @click.prevent="$router.push('/refund-page-cms')">
              <i class="bx bx-right-arrow-alt"></i>Terms & Condition Page CMS
            </a>
          </li>
          <li>
            <a href="/privacy-page-cms" @click.prevent="$router.push('/privacy-page-cms')">
              <i class="bx bx-right-arrow-alt"></i>Privacy Page CMS
            </a>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>