import { authHeader } from "../../../../auth";

export default {
    name: "AddNewSizeGuide",
    data() {
        return {
            specificationKey: [],
            specificationValue: [],
            specificationArray: {},
            temp:{},
            counter: 1,
            cmsData: {},
            categoryName: "",
            data: [[{ key: '', value: '' }]]
        }
    },
    async created() {
        document.title = "Hyman Fashion - Add New Size Guide";
        await this.getAllParentCategories();
    },
    methods: {
        getCategoryList: async function (id) {
            this.categoryList = [];
            this.category = "";
            this.categoryList.push({ id: '', name: 'No Category' });
            let url = this.$serverURL + this.$api.product.getAllCategories + '?categoryId=' + id;
            let config = {
                method: "GET",
                url: url,
                headers: {
                    "Authorization": authHeader()
                }
            }
            await this.$axios(config).then(async (response) => {
                if (response.status == 200) {

                    this.subCategoryList = response.data.data;
                    this.subCategoryList.push({ id: '', name: 'No Sub-Category' });

                }
                else {

                    this.$swal.fire({
                        icon: "error",
                        text: "Failed! please try again."
                    });
                }
            }).catch(error => {

                this.$swal.fire({
                    icon: "error",
                    text: "Failed! please try again." + error
                });
            });
        },
        addSpecification(index) {
            const temp = this.data?.[index]
            if(temp?.[temp.length -1].key != "" && temp?.[temp.length -1].value != "") {
                this.data?.[index].push({ key: "", value: "" });
            }
        },
        addMoreGuide() {
            const temp = this.data?.[this.data?.length - 1]
            if (temp?.length > 0 && temp?.[0].key != "" && temp?.[0].value != "") {
                this.data.push([]);
                this.data?.[this.data?.length - 1].push({ key: "", value: "" });
            }
        },
        finalSpecification() {
            if (!this.categoryName) {
                this.showToaster('Please enter price!')
                return false;
            }
            let index = this.counter - 1;
            if (!this.specificationKey[index]) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter size guide Name!"
                });
                return false;
            }
            if (!this.specificationValue[index]) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter guide size!"
                });
                return false;
            }
            return true;
        },
        deleteGuide(index) {
            this.data.splice(index, 1);
        },
        deleteSpecificationRow(index, pos) {
            this.data?.[index].splice(pos, 1);
        },
        addEntity: async function () {
            // this.specificationArray = {};
            // for (let i = 0; i < this.counter; i++) {
            //     this.specificationArray[this.specificationKey[i]] = this.specificationValue[i];
            // }
            // if (!this.finalSpecification()) {
            //     return;
            // }
            // // console.log("Final specification array: ", JSON.stringify(this.specificationArray));
            // this.$swal.showLoading();
            
            const url = this.$serverURL + this.$api.product.productSizeGuide;
            const data = {
                categoryId: this.categoryName.id,
                categoryName: this.categoryName.name,
                guide: JSON.stringify(this.data)
            };

            const config = {
                method: "POST",
                url,
                data,
                headers: {
                    "Authorization": authHeader()
                }
            };

            // this.$swal.showLoading();
            await this.postData(config).then(async (response) => {
                if (response.statusCode == 201) {
                    // this.showToaster(response.message, 'success');
                    
                    this.$router.push('/product-size-guide');
                }
            });
            // this.$swal.hideLoading();
        }
    }
}