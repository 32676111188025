import { authHeader } from "../../../../auth";

export default {
  name: "EditSizeGuide",
  data() {
    return {
      sizeGuideId: this.$route.params.id,
      specificationKey: [],
      specificationValue: [],
      specificationArray: {},
      temp: {},
      counter: 1,
      cmsData: {},
      categoryName: {},
      data: [[{ key: '', value: '' }]]
    };
  },
  async created() {
    document.title = "Hyman Fashion - Edit Size Guide";
    await this.getSizeGuideDetails();
    await this.getAllParentCategories();
  },
  methods: {
    getCategoryList: async function (id) {
        this.categoryList = [];
        this.category = "";
        this.categoryList.push({ id: '', name: 'No Category' });
        let url = this.$serverURL + this.$api.product.getAllCategories + '?categoryId=' + id;
        let config = {
            method: "GET",
            url: url,
            headers: {
                "Authorization": authHeader()
            }
        }
        await this.$axios(config).then(async (response) => {
            if (response.status == 200) {

                this.subCategoryList = response.data.data;
                this.subCategoryList.push({ id: '', name: 'No Sub-Category' });

            }
            else {

                this.$swal.fire({
                    icon: "error",
                    text: "Failed! please try again."
                });
            }
        }).catch(error => {

            this.$swal.fire({
                icon: "error",
                text: "Failed! please try again." + error
            });
        });
    },
    getSizeGuideDetails: async function() {
      try {
        const url = this.$serverURL +this.$api.product.productSizeGuide +'/'+ this.sizeGuideId;
        const config = {
          method: "GET",
          url: url,
          headers: {
            "Authorization": authHeader()
          }
        };

        const response = await this.$axios(config);
        if (response.status === 200) {
            console.log(response?.data?.data)
            this.categoryName.id = response?.data?.data[0].categoryId;
            this.categoryName.name = response?.data?.data[0].categoryName;
            this.data = JSON.parse(response?.data?.data[0].guide)
          }
      } catch (error) {
        this.$swal.fire({
          icon: "error",
          text: "Failed to fetch size guide details. Please try again."
        });
      }
    },
    addMoreGuide() {
        const temp = this.data?.[this.data?.length - 1]
        if (temp?.length > 0 && temp?.[0].key != "" && temp?.[0].value != "") {
            this.data.push([]);
            this.data?.[this.data?.length - 1].push({ key: "", value: "" });
        }
    },
    addSpecification(index) {
        const temp = this.data?.[index]
        if(temp?.[temp.length -1].key != "" && temp?.[temp.length -1].value != "") {
            this.data?.[index].push({ key: "", value: "" });
        }
    },
    deleteGuide(index) {
        this.data.splice(index, 1);
    },
    deleteSpecificationRow(index, pos) {
        this.data?.[index].splice(pos, 1);
    },
    updateSpecification(index) {
      const temp = this.data?.[index];
      if (temp?.[temp.length - 1].key !== "" && temp?.[temp.length - 1].value !== "") {
        this.data?.[index].push({ key: "", value: "" });
      }
    },
    async updateGuide() {

      const url = this.$serverURL +this.$api.product.productSizeGuide + '/' + this.sizeGuideId;
      const data = {
        categoryId: this.categoryName.id,
        categoryName: this.categoryName.name,
        guide: JSON.stringify(this.data)
      };

      const config = {
        method: "PATCH",
        url: url,
        data: data,
        headers: {
          "Authorization": authHeader()
        }
      };

      await this.updateDataToBackend(config).then(async (response) => {
        if (response.statusCode == 200) {
            // this.showToaster(response.message, 'success');
            
            this.$router.push('/product-size-guide');
        }
    });
    }
  }
};
