<template>
  <div class="page-wrapper">
    <div class="page-content">
      <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb mb-0 p-0">
            <li class="breadcrumb-item">
              <router-link to="/home">
                <i class="bx bx-home-alt"></i>
              </router-link>
            </li>
            <li class="breadcrumb-item active" aria-current="page">Our Values List</li>
          </ol>
        </nav>

        <div class="ms-auto">
          <div class="btn-group">
            <button
              type="button"
              class="btn btn-primary"
              data-bs-toggle="modal"
              data-bs-target="#addModal"
            >
              <i class="bx bxs-plus-square"></i>Add New Value
            </button>
          </div>
          <!-- Add Modal -->

          <div
            class="modal fade"
            id="addModal"
            tabindex="-1"
            aria-labelledby="addModalLabel"
            aria-hidden="true"
          >
            <form @submit.prevent="addOurValues" enctype="multipart/form-data">
              <div class="modal-dialog modal-lg">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="addModalLabel">Add Our Value</h5>
                    <button
                      type="button"
                      class="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div class="modal-body">
                    <label class="form-label">Our Value Title:</label>
                    <input
                      class="form-control mb-1"
                      type="text"
                      v-model="title"
                      placeholder="Our Value Title"
                      aria-label="default input example"
                    />
                  </div>
                  <div class="form-group m-3">
                    <label for="exampleInputEmail1">
                      Image: [
                      <strong>image size</strong>]
                    </label>

                    <input
                      class="form-control mb-1"
                      type="file"
                      aria-label="default input example"
                      ref="addImage"
                      @change="handleUploadImage('image', 'addImage', $event)"
                    />
                    <div class="col-12 mt-3">
                      <img
                        style="position: relative; left: 11px"
                        v-if="preview.image"
                        :src="preview.image"
                        height="70"
                      />
                    </div>
                  </div>
                  <div class="form-group m-3">
                    <label>Our Values Subtitle</label>
                    <ckeditor :editor="editor" v-model="subtitle" :config="editorConfig"></ckeditor>
                  </div>
                  <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <button type="submit" class="btn btn-primary" data-bs-dismiss="modal">Add</button>
                  </div>
                </div>
              </div>
            </form>
          </div>

          <!-- Add Modal -->
          <!-- Edit Role Modal -->

          <div
            class="modal fade"
            id="editModal"
            tabindex="-1"
            aria-labelledby="addModalLabel"
            aria-hidden="true"
          >
            <form @submit.prevent="updateOurValues">
              <div class="modal-dialog modal-lg">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="addModalLabel">Update Our Value</h5>
                    <button
                      type="button"
                      class="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div class="modal-body">
                    <label class="form-label">Our Value Name:</label>
                    <input
                      class="form-control mb-1"
                      type="text"
                      v-model="singleValue.title"
                      placeholder="Our Value Title"
                      aria-label="default input example"
                    />
                  </div>
                  <div class="form-group m-3">
                    <label for="exampleInputEmail1">
                      Image [
                      <strong>
                        Get image URL from any
                        site.
                      </strong>]
                    </label>
                    <input
                      class="form-control mb-1"
                      type="file"
                      placeholder="Enter value content image URL"
                      aria-label="default input example"
                      ref="updateImage"
                      @change="handleUploadUpdateImage('image', 'updateImage', $event)"
                    />
                    <div class="col-12 mt-3">
                      <img
                        style="position: relative; left: 11px"
                        v-if="previewUpdateImage"
                        :src="previewUpdateImage"
                        height="70"
                      />
                      <img
                        style="position: relative; left: 11px"
                        v-else-if="singleValue.image"
                        :src="singleValue.image"
                        height="70"
                      />
                      <img
                        style="position: relative; left: 11px"
                        v-else
                        src="/core/assets/noImage.jpg"
                        height="70"
                      />
                    </div>
                  </div>
                  <div class="form-group m-3">
                    <label>Our Values Subtitle</label>
                    <ckeditor
                      :editor="editor"
                      v-model="singleValue.subtitle"
                      :config="editorConfig"
                    ></ckeditor>
                  </div>
                  <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <button type="submit" class="btn btn-primary" data-bs-dismiss="modal">Update</button>
                  </div>
                </div>
              </div>
            </form>
          </div>

          <!-- Edit Role Modal -->
        </div>
      </div>
      <h6 class="mb-0 text-uppercase">Our Values List</h6>
      <hr />
      <div class="card">
        <div class="card-body">
          <div class="table-responsive">
            <table id="example" class="table table-striped table-bordered">
              <thead>
                <tr>
                  <th>SL</th>
                  <th>Title</th>
                  <th>Image URL</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(data, index) in dataList" :key="index">
                  <td>{{ index + 1 }}</td>
                  <td>{{ data.title }}</td>
                  <td>
                    <img :src="data.image" alt height="90" width="110" />
                  </td>
                  <td>
                    <div class="d-flex order-actions">
                      <a
                        href="javascript:void(0);"
                        v-on:click="editOurValues(data)"
                        data-bs-toggle="modal"
                        data-bs-target="#editModal"
                        class="edit-button"
                      >
                        <i class="bx bxs-edit text-white"></i>
                      </a>
                      <a
                        href="javascript:void(0);"
                        v-on:click="deleteOurValue(data.id)"
                        class="ms-3 delete-button"
                      >
                        <i class="bx bxs-trash text-white"></i>
                      </a>
                    </div>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <th>SL</th>
                  <th>Title</th>
                  <th>Image URL</th>
                  <th>Action</th>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script src='../js/our-values.js'></script>
  
  
<style scoped>
.edit-button {
  background-color: #17a00e;
}

.delete-button {
  background-color: #f41127;
}
</style>