import { authHeader } from "../../../auth";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default {
    name: "Coupon",
    data() {
        return {
            editor: ClassicEditor,
            setURL: this.$serverURL + this.$api.coupon.couponAPI,
            singleAPI: this.$serverURL + this.$api.coupon.singleCouponAPI,
            singleCoupon: {},
            couponName: '',
            couponCode: '',
            expireDate: '',
            discount: '',
            couponTypes: ['welcome', 'free shipping', 'fixed'],
            couponType: '',
            filteredCouponTypes: [],
            currentExpireDate: '',
            isWelcomeCouponExist: false,
            isFreeShippingExist: false
        }
    },
    async created() {
        document.title = "Hyman Fashion - Coupon";
        await this.getDataList(this.setURL);
        await this.handleCoupon();
        var scripts = [
            "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
            "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
            "/core/assets/js/table-datatable.js"
        ];
        scripts.forEach(script => {
            let tag = document.createElement("script");
            tag.setAttribute("src", script);
            document.head.appendChild(tag);
        });
    },
    methods: {
        handleCoupon: function () {
            this.isWelcomeCouponExist = false;
            this.isFreeShippingExist = false;
            if (this.dataList.length > 0) {
                for (let i = 0; i < this.dataList.length; i++) {
                    if (this.dataList[i].couponType == 'welcome') {
                        this.isWelcomeCouponExist = true;
                    } else if (this.dataList[i].couponType == 'free shipping') {
                        this.isFreeShippingExist = true;
                    }
                }
            }
            this.filteredCouponTypes = this.couponTypes;
            let tempCouponType = this.filteredCouponTypes;
            if (this.isWelcomeCouponExist == true) {
                this.filteredCouponTypes = tempCouponType.filter(el => el != 'welcome');
            }
            if (this.isFreeShippingExist == true) {
                tempCouponType = this.filteredCouponTypes;
                this.filteredCouponTypes = tempCouponType.filter(el => el != 'free shipping');
            }
        },
        addCoupon: async function () {
            if (!this.couponName) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter coupon name!"
                });
            }
            else if (!this.couponCode) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter coupon code!"
                });
            }
            else if (!this.expireDate) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter coupon expire date!"
                });
            }
            else if (!this.couponType) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter coupon type!"
                });
            }
            else if (!this.discount) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter discount amount!"
                });
            }
            else {
                let data = {
                    couponName: this.couponName,
                    couponCode: this.couponCode,
                    expireDate: this.expireDate,
                    couponType: this.couponType,
                    discount: this.discount,
                };
                let config = {
                    method: "POST",
                    url: this.setURL,
                    data: data,
                    headers: {
                        "Authorization": authHeader()
                    }
                };
                await this.postDataToBackend(config);
                this.handleCoupon();
                await this.getDataList(this.setURL);
                this.couponName = '';
                this.couponCode = '';
                this.expireDate = '';
                this.couponType = '';
                this.discount = '';
                document.getElementById('addCouponModal').click();
                document.getElementById('addFSCouponModal').click();

            }
        },
        // addFSCoupon: async function () {
        //     if (!this.couponName || !this.couponCode || !this.expireDate || !this.discount) {
        //         this.$swal.fire({
        //             icon: "error",
        //             text: "Please enter Coupon name, Coupon expireDate, discount amount!"
        //         });
        //     }
        //     else {
        //         let data = {
        //             couponName: this.couponName,
        //             couponCode: this.couponCode,
        //             expireDate: this.expireDate,
        //             couponType: 'free shipping',
        //             discount: this.discount,
        //         };
        //         let config = {
        //             method: "POST",
        //             url: this.setURL,
        //             data: data,
        //             headers: {
        //                 "Authorization": authHeader()
        //             }
        //         };
        //         await this.postDataToBackend(config);
        //         document.getElementById('addCouponModal').click();
        //         document.getElementById('addFSCouponModal').click();
        //         this.couponName = '';
        //         this.couponCode = '';
        //         this.expireDate = '';
        //         this.couponType = '';
        //         this.discount = '';
        //         await this.getDataList(this.setURL);
        //     }
        // },
        editCoupon: async function (data) {
            this.singleCoupon = data;
            this.currentExpireDate = this.$filters.formatUpdateDate(data.expireDate).toString();
        },
        updateCoupon: async function () {
            if (!this.singleCoupon.couponName) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter coupon name!"
                });
            }
            else if (!this.singleCoupon.couponCode) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter coupon code!"
                });
            }
            else if (!this.singleCoupon.expireDate) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter coupon expire date!"
                });
            }
            else if (!this.singleCoupon.couponType) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter coupon type!"
                });
            }
            else if (!this.singleCoupon.discount) {
                this.$swal.fire({
                    icon: "error",
                    text: "Please enter discount amount!"
                });
            }
            else {
                let data = {
                    couponName: this.singleCoupon.couponName,
                    couponCode: this.singleCoupon.couponCode,
                    expireDate: this.singleCoupon.expireDate,
                    couponType: this.singleCoupon.couponType,
                    discount: this.singleCoupon.discount,
                };
                let config = {
                    method: "PATCH",
                    url: this.singleAPI + this.singleCoupon.id,
                    data: data,
                    headers: {
                        "Authorization": authHeader()
                    }
                };
                await this.updateDataToBackend(config);
                this.handleCoupon()
                await this.getDataList(this.setURL);
                this.singleCoupon = {};
                this.currentExpireDate = '';
                document.getElementById('closeButton').click();
            }
        },
        deleteCoupon: function (id) {
            this.$swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Delete'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        let config = {
                            method: 'DELETE',
                            url: this.singleAPI + id,
                            headers: {
                                "Authorization": authHeader()
                            }
                        }
                        await this.deleteDataToBackend(config);
                        this.handleCoupon();
                        await this.getDataList(this.setURL);
                    } catch (error) {
                        console.log(error);
                        this.$swal.fire({
                            icon: "error",
                            text: "Something went wrong, please try again later."
                        });
                    }
                }
            })
        }
    }
}