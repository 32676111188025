import { authHeader } from "../../../../auth";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
export default {
    name: "Our Values",
    data() {
        return {
            editor: ClassicEditor,
            ourValuesList: [],
            singleValue: {},
            title: "",
            image: "",
            subtitle: "",
            preview: {
                image: ""
            },
            previewUpdateImage: "",
            setURL: this.$serverURL + this.$api.aboutUs.ourValues,
            setSingleContentURL: this.$serverURL + this.$api.aboutUs.singleValue,
        }
    },
    async created() {
        document.title = "Hyman Fashion - Our Values";
        await this.getDataList(this.setURL);
        var scripts = [
            "/core/assets/plugins/datatable/js/jquery.dataTables.min.js",
            "/core/assets/plugins/datatable/js/dataTables.bootstrap5.min.js",
            "/core/assets/js/table-datatable.js"
        ];
        scripts.forEach(script => {
            let tag = document.createElement("script");
            tag.setAttribute("src", script);
            document.head.appendChild(tag);
        });

        let style = document.createElement('link');
        style.type = "text/css";
        style.rel = "stylesheet";
        style.href = '/core/assets/plugins/datatable/css/dataTables.bootstrap5.min.css';
        document.head.appendChild(style);
    },
    methods: {
        handleUploadImage(previewImageKey, ref, event) {
            this.image = event.target.files[0];
            let input = this.$refs[ref];
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader
                reader.onload = e => {
                    this.preview[previewImageKey] = e.target.result
                }
                reader.readAsDataURL(file[0])
            }
        },
        handleUploadUpdateImage(previewImageKey, ref, event) {
            this.singleValue.image = event.target.files[0];
            let input = this.$refs[ref];
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader
                reader.onload = e => {
                    this.previewUpdateImage = e.target.result
                }
                reader.readAsDataURL(file[0])
            }
        },
        addOurValues: async function () {
            if (!this.title || !this.subtitle || !this.image) {

                this.$swal.fire({
                    icon: "error",
                    text: "Please enter Content title, image URL and subtitle!"
                });
            }
            else {
                // let data = {
                //     title: this.title,
                //     image: this.image,
                //     subtitle: this.subtitle,
                // };
                const formData = new FormData();
                formData.append('title', this.title);
                formData.append('image', this.image);
                formData.append('subtitle', this.subtitle);
                let config = {
                    method: "POST",
                    url: this.setURL,
                    data: formData,
                    headers: {
                        "Authorization": authHeader()
                    }
                };
                await this.postDataToBackend(config);
                this.title = '';
                this.image = '';
                this.subtitle = '';
                this.preview = {
                    image: ""
                };
                this.$refs.addImage.value = null;
                await this.getDataList(this.setURL);
            }
        },
        editOurValues: function (data) {
            this.singleValue = data;
        },
        updateOurValues: async function () {
            if (!this.singleValue.title || !this.singleValue.subtitle || !this.singleValue.image) {

                this.$swal.fire({
                    icon: "error",
                    text: "Please enter Content title, image URL and subtitle!"
                });
            }
            else {
                // let data = {
                //     title: this.singleValue.title,
                //     image: this.singleValue.image,
                //     subtitle: this.singleValue.subtitle,
                // };
                const formData = new FormData();
                formData.append('title', this.singleValue.title);
                formData.append('image', this.singleValue.image);
                formData.append('subtitle', this.singleValue.subtitle);
                let config = {
                    method: "PATCH",
                    url: this.setSingleContentURL + this.singleValue.id,
                    data: formData,
                    headers: {
                        "Authorization": authHeader()
                    }
                };
                await this.updateDataToBackend(config);
                this.singleValue = {};
                this.$refs.updateImage.value = null;
                this.previewUpdateImage = ""
                await this.getDataList(this.setURL);
            }
        },
        deleteOurValue: async function (id) {
            this.$swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        let config = {
                            method: 'DELETE',
                            url: this.setSingleContentURL + id,
                            headers: {
                                "Authorization": authHeader(),
                            }
                        }
                        await this.deleteDataToBackend(config);
                        await this.getDataList(this.setURL);

                    } catch (error) {
                        this.$swal.fire({
                            icon: "error",
                            text: "Something went wrong, please try agian later." + error
                        });
                    }
                }
            })

        },

    }
}