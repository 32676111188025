<template>
  <div class="page-wrapper">
    <div class="page-content">
      <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb mb-0 p-0">
            <li class="breadcrumb-item">
              <router-link to="/home"><i class="bx bx-home-alt"></i></router-link>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              Add Product
            </li>
          </ol>
        </nav>
        <div class="ms-auto"></div>
      </div>
      <hr />
      <form>
        <div class="card">
          <div class="card-body p-4">
            <h5 class="card-title">Add New Product</h5>
            <hr />
            <div class="form-body mt-4">

              <div class="row">
                <div class="col-lg-8">
                  <div class="border border-3 p-4 rounded mb-4">
                    <div class="mb-3">
                      <label for="inputProductTitle" class="form-label">Product Name:</label>
                      <input type="text" class="form-control" id="inputProductTitle" v-model="name"
                        placeholder="Enter product name" />
                    </div>
                    <div class="mb-3">
                      <label for="inputProductDescription" class="form-label">Short Description:</label>
                      <ckeditor :editor="editor" v-model="shortDescription" :config="editorConfig"></ckeditor>
                    </div>
                    <div class="mb-3">
                      <label for="inputProductDescription" class="form-label">Long Description:</label>
                      <ckeditor :editor="editor" v-model="longDescription" :config="editorConfig"></ckeditor>
                    </div>
                  </div>
                  <div class="border border-3 p-4 rounded mb-3">
                    <div class="row">
                      <div class="col-sm-4">
                        <label for="inputVendor" class="form-label">Category:</label>
                        <select class="form-select" v-model="parentCategory" id="inputVendor"
                          @change="getCategoryList(parentCategory.id)">
                          <option style="display: none" selected value="">
                            Select Category
                          </option>

                          <option v-for="(data, index) in parentCategoriesList" :key="index"
                            :value="{ id: data.id, name: data.name }">
                            {{ data.name }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="border border-3 p-4 rounded mb-3">
                    <label for="exampleInputEmail1">Featured Image:
                      <strong>[Preferred Image Size: 400X400, Max Upload Limit:
                        1MB]</strong></label>
                    <input class="form-control mb-2" type="file" name="featured_image" id="featuredImage"
                      ref="featuredImageInput" aria-describedby="imageHelp" accept="image/*"
                      @change="uploadFeaturedImage()" />
                    <img v-if="previewFeaturedImage" :src="previewFeaturedImage" height="90" />
                    <div id="display_image"></div>
                  </div>
                  <div class="border border-3 p-4 rounded mb-4">
                    <label for="exampleInputEmail1" class="mb-3">Product Images (Max Limit 3):
                      <strong>[Preferred Image Size: 900X550, Max Upload Limit:
                        1MB]</strong></label>
                    <input type="file" style="display: none" @change="uploadProductImages" ref="productImages" />

                    <div class="card text-center">
                      <div @click="$refs.productImages.click()" class="card-body btn btn-outline-primary" :style="[
                          disableButton == true
                            ? { 'pointer-events': 'none' }
                            : '',
                        ]">
                        <h5>Select Image</h5>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm-3 mb-2" v-for="(data, index) in previewProductImages" :key="index">
                        <i class="fa-solid fa-circle-xmark close-button" @click="removeUploadedProductImage(index)"></i>
                        <img :src="data" height="90" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="border border-3 p-4 rounded">
                    <div class="row g-3">
                      <div class="col-md-6">
                        <label for="inputPrice" class="form-label">Price:</label>
                        <input type="number" step="any" class="form-control" id="inputPrice" v-model="price"
                          placeholder="00.00" />
                      </div>
                      <div class="col-md-6">
                        <label class="form-label">Discount Price</label>
                        <input type="number" step="any" class="form-control" pattern="[0-9]*" v-model="discountPrice"
                          placeholder="00.00" />
                      </div>
                      <div class="col-md-6">
                        <label class="form-label">Cost</label>
                        <input type="number" step="any" class="form-control" pattern="[0-9]*" v-model="cost"
                          placeholder="00.00" />
                      </div>

                      <div class="col-md-6">
                        <label for="inputPrice" class="form-label">Stock:</label>
                        <input type="number" class="form-control" id="inputPrice" v-model="stock" placeholder="stock" />
                      </div>
                      <div class="col-md-12">
                        <label for="inputStarPoints" class="form-label">Outer Shell:</label>
                        <input type="text" class="form-control" id="inputStarPoints" v-model="outerShell" placeholder="Outer Shell" />
                      </div>
                      <div class="col-md-12">
                        <label for="inputStarPoints" class="form-label">Lining:</label>
                        <input type="text" class="form-control" id="inputStarPoints" v-model="lining" placeholder="Lining" />
                      </div>
                      <div class="col-md-6">
                        <label for="inputStarPoints" class="form-label">SKU:</label>
                        <input type="text" class="form-control" id="inputStarPoints" v-model="sku" placeholder="SKU" />
                      </div>
                      <div class="col-md-6">
                        <label for="inputStarPoints" class="form-label">Weight:</label>
                        <input type="text" class="form-control" id="inputStarPoints" v-model="weight" placeholder="Weight" />
                      </div>
                      <div class="col-12">
                        <div class="row">
                          <div class="col-sm-12">
                            <label for="inputCollection" class="form-label">Sizes:</label>
                            <Multiselect v-model="size" mode="tags" :close-on-select="false" :searchable="true"
                              :create-option="true" :options="sizesList" />
                          </div>
                          <div class="col-sm-12">
                            <label for="inputCollection" class="form-label">Colors:</label>
                            <Multiselect v-model="color" mode="tags" :close-on-select="false" :searchable="true"
                              :create-option="true" :options="colorsList" />
                          </div>
                          <div class="col-sm-12">
                            <label for="inputCollection" class="form-label">Sales:</label>
                            <Multiselect v-model="sales" mode="tags" :close-on-select="false" :searchable="true"
                              :create-option="true" :options="salesList" />
                          </div>
                          <div class="col-sm-12">
                            <label for="inputCollection" class="form-label">Offers:</label>
                            <Multiselect v-model="offers" mode="tags" :close-on-select="false" :searchable="true"
                              :create-option="true" :options="offersList" />
                          </div>
                          <div class="col-sm-12">
                            <label for="inputCollection" class="form-label">Cares:</label>
                            <Multiselect v-model="cares" mode="tags" :close-on-select="false" :searchable="true"
                              :create-option="true" :options="caresList" />
                          </div>
                          <div class="col-sm-12">
                            <label for="inputCollection" class="form-label">Perks:</label>
                            <Multiselect v-model="perks" mode="tags" :close-on-select="false" :searchable="true"
                              :create-option="true" :options="perksList" />
                          </div>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="form-group">
                          <label for="exampleInputEmail1">Ready to Ship: </label>
                          <div class="form-check form-switch mt-1">
                            <input class="form-check-input" type="checkbox" id="flexSwitchCheckChecked"
                              v-model="isReadyToShip" />
                          </div>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="form-group">
                          <label for="exampleInputEmail1">Is Deactive: </label>
                          <div class="form-check form-switch mt-1">
                            <input class="form-check-input" type="checkbox" id="flexSwitchCheckChecked"
                              v-model="isDeactive" />
                          </div>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="d-grid">
                          <button type="button" @click="addProduct" class="btn btn-primary">
                            Save Product
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!--end row-->
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="modal fade" id="addModal" tabindex="-1" aria-labelledby="addModalLabel" aria-hidden="true">
      <form @submit.prevent="addDiscount" enctype="multipart/form-data">
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="addModalLabel">Add Discount</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <label class="form-label">Discount Percentage or Amount:</label>
              <input class="form-control mb-1" type="text" v-model="discountTitle" placeholder="e.g 25%, 30%, 10, 20 etc"
                aria-label="default input example" />
            </div>

            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                Close
              </button>
              <button type="submit" class="btn btn-primary" data-bs-dismiss="modal">
                Add
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script src='../js/add-product.js'></script>

<style scoped>
@import "../css/add-product.css";
</style>