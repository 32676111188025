<template>
    <div class="page-wrapper">
      <div class="page-content">
        <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb mb-0 p-0">
              <li class="breadcrumb-item">
                <router-link to="/home"><i class="bx bx-home-alt"></i></router-link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                Update Blog
              </li>
            </ol>
          </nav>
          <div class="ms-auto"></div>
        </div>
        <hr />
        <form>
          <div class="card">
            <div class="card-body p-4">
              <h5 class="card-title">Update Blog</h5>
              <hr />
              <div class="form-body mt-4">
                <div class="row">
                  <div class="col-lg-8">
                    <div class="border border-3 p-4 rounded mb-3">
                      <div class="mb-3">
                        <label for="inputProductTitle" class="form-label">Blog Name</label>
                        <input type="email" class="form-control" id="inputProductTitle" placeholder="blog name"
                          v-model="singleBlog.title" />
                      </div>
                      <div class="mb-3">
                        <label for="inputProductTitle" class="form-label">Author</label>
                        <input type="text" class="form-control" id="inputProductTitle" placeholder="author name"
                          v-model="singleBlog.authorName" />
                      </div>
                      <div class="mb-3">
                        <label class="form-label">Author Image: <strong>[Preferred Image Size: 40X40, Max Upload Limit:
                            1MB]</strong></label>
                        <input class="form-control mb-2" type="file" name="author_image" id="authorImage" ref="AuthorImage"
                          aria-describedby="imageHelp" accept="image/*" @change="uploadAuthorImage" />
                        <img v-if="previewAuthorImage" :src="previewAuthorImage" height="80" width="130" />
                        <img v-else :src="singleBlog.authorImage" height="80" width="130" />
                      </div>
  
                      <div class="mb-3">
                        <label class="form-label">Blog Photo: <strong>[Preferred Image Size: 1440X2114, Max Upload Limit:
                            1MB]</strong></label>
                        <input class="form-control mb-2" type="file" name="featured_image" id="featuredImage"
                          ref="PhotoInput" aria-describedby="imageHelp" accept="image/*" @change="uploadBlogPhoto" />
                        <img v-if="previewPhoto" :src="previewPhoto" height="80" width="130" />
                        <img v-else :src="singleBlog.photo" height="80" width="130" />
                      </div>
                    </div>
                    <div class="border border-3 p-4 rounded mb-3">
                      <div class="mb-3">
                        <label class="form-label">Description</label>
                        <ckeditor :editor="editor" v-model="singleBlog.blogDescription" :config="editorConfig"></ckeditor>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-8">
                      <div class="border border-3 p-4 rounded">
                        <div class="mb-3">
                        <label for="inputUrlSlug" class="form-label">Url Slug</label>
                        <input type="text" class="form-control" id="inputUrlSlug" placeholder="url slug"
                          v-model="singleBlog.urlSlug" disabled/>
                      </div>
                        <div class="mb-3">
                          <label class="form-label">Publish Date</label>
                          <input type="date" v-model="singleBlog.publishedAt" class="form-control input-group">
                        </div>
                        <div>
                          <div class="d-grid">
                            <button type="button" @click="updateBlogPost" class="btn btn-primary">
                              Update Blog
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
                <!--end row-->
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </template>
  
  <script src='../js/update-blog-post.js'></script>