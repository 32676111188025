<template>
    <div class="page-wrapper">
        <div class="page-content">
            <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb mb-0 p-0">
                        <li class="breadcrumb-item">
                            <router-link to="/home">
                                <i class="bx bx-home-alt"></i>
                            </router-link>
                        </li>
                        <li class="breadcrumb-item active" aria-current="page">Edit Size Guide</li>
                    </ol>
                </nav>
                <div class="ms-auto"></div>
            </div>
            <hr/>
            <form>
                <div class="card">
                    <div class="card-body p-4">
                        <h5 class="card-title">Edit Size Guide</h5>
                        <hr />
                        <div class="form-body mt-4">
                            <div class="row">
                                <div class="col-lg-8">
                                    <div class="border border-3 p-4 rounded mb-3">
                                        <div class="col-sm-6 mb-3">
                                            <label for="inputVendor" class="form-label">Category Name:</label>
                                            <select class="form-select" v-model="categoryName" id="inputVendor"
                                            @change="getCategoryList(categoryName.id)">
                                            <option style="display: none" selected value="">
                                                Select Category
                                            </option>

                                            <option v-for="(data, index) in parentCategoriesList" :key="index"
                                                :value="{ id: data.id, name: data.name }">
                                                {{ data.name }}
                                            </option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="border border-3 p-4 rounded mb-3" v-for="(items, index) in data" :key="index">
                                        <div class="d-flex justify-content-end">
                                            <button v-if="index > 0" type="button" @click="deleteGuide(index)" class="btn btn-warning">X</button>
                                        </div>
                                        <div class="row mb-3" v-for="(item, pos) in items" :key="pos">
                                            <div class="col-sm-6">
                                                <label class="form-label">Guide Name:</label>
                                                <input type="text" class="form-control" v-model="item.key"/>
                                            </div>
                                            <div class="col-sm-6">
                                                <label class="form-label">Guide Size:</label>
                                                <div class="d-flex gap-2">
                                                    <input type="text" class="form-control" v-model="item.value"/>
                                                    <button v-if="pos > 0" @click="deleteSpecificationRow(index, pos)" type="button" class="btn btn-danger">X</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mb-3">
                                            <button type="button" @click="addSpecification(index)" class="btn btn-primary">Add More</button>
                                        </div>
                                    </div>
                                    <div class="d-flex justify-content-between">
                                        <div class="col-3">
                                            <div class="d-grid">
                                                <button type="button" @click="addMoreGuide()" class="btn btn-success">Add More Guide</button>
                                            </div>
                                        </div>
                                        <div class="col-3">
                                            <div class="d-grid">
                                                <button type="button" @click="updateGuide()" class="btn btn-primary">Save</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>
  
<script src='../js/edit-size-guide.js'></script>
  
  