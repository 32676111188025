export default {
    data() {
        return {
            url: this.$serverURL + this.$api.cms.aboutUsPageCMS,
            cmsData: {
                id: "",
                pageTitle: "",
                sectionTitle: "",
                sectionSubTitle: "",
                image: "",
                achievementTitleOne: "",
                achievementNumberOne: "",
                achievementTitleTwo: "",
                achievementNumberTwo: "",
                achievementTitleThree: "",
                achievementNumberThree: "",
                achievementTitleFour: "",
                achievementNumberFour: "",
                valueSectionTitle: "",
                valueSectionSubTitle: "",
                faqSectionTitle: "",
                faqSectionSubTitle: ""
            },
            preview: {
                image: ""
            }
        }
    },
    async created() {
        document.title = "Hyman Fashion - About Us Page CMS";
        this.getData();
    },
    methods: {
        async getData() {
            try {
                let response = await this.getCmsData(this.url);
                if (response.status == 200) {
                    this.cmsData = (response.data.data.length > 0) ? response.data.data[0] : {};
                }
                else {
                    this.cmsData = {};
                }
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Failed! please try again." + error
                });
            }
        },
        async update() {
            try {
                let fromData = new FormData();
                fromData.append('id', this.cmsData.id);
                fromData.append('pageTitle', this.cmsData.pageTitle);
                fromData.append('sectionTitle', this.cmsData.sectionTitle);
                fromData.append('sectionSubTitle', this.cmsData.sectionSubTitle);
                fromData.append('image', this.cmsData.image);
                fromData.append('achievementTitleOne', this.cmsData.achievementTitleOne);
                fromData.append('achievementNumberOne', this.cmsData.achievementNumberOne);
                fromData.append('achievementTitleTwo', this.cmsData.achievementTitleTwo);
                fromData.append('achievementNumberTwo', this.cmsData.achievementNumberTwo);
                fromData.append('achievementTitleThree', this.cmsData.achievementTitleThree);
                fromData.append('achievementNumberThree', this.cmsData.achievementNumberThree);
                fromData.append('achievementTitleFour', this.cmsData.achievementTitleFour);
                fromData.append('achievementNumberFour', this.cmsData.achievementNumberFour);
                fromData.append('valueSectionTitle', this.cmsData.valueSectionTitle);
                fromData.append('valueSectionSubTitle', this.cmsData.valueSectionSubTitle);
                fromData.append('faqSectionTitle', this.cmsData.faqSectionTitle);
                fromData.append('faqSectionSubTitle', this.cmsData.faqSectionSubTitle);
                this.$swal.showLoading();
                let response = await this.createUpdateCMS(this.url, fromData);
                if (response.statusCode == 200 || response.statusCode == 201) {
                    this.$swal.fire({
                        toast: true,
                        position: 'top-end',
                        text: response.message,
                        icon: "success",
                        showConfirmButton: false,
                        timer: 3000,
                        animation: false,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                        }
                    });
                }
                await this.getData();
            } catch (error) {
                this.$swal.fire({
                    icon: "error",
                    text: "Something went wrong. Please try again!"
                });
            } finally {
                this.$swal.hideLoading();
            }
        },
        handleUploadImage(imageKey, previewImageKey, ref, event) {
            this.cmsData[imageKey] = event.target.files[0];
            let input = this.$refs[ref];
            let file = input.files;
            if (file && file[0]) {
                let reader = new FileReader
                reader.onload = e => {
                    this.preview[previewImageKey] = e.target.result
                }
                reader.readAsDataURL(file[0])
            }
        },
    }
}