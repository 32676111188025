<template>
  <div class="page-wrapper">
    <div class="page-content">
      <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb mb-0 p-0">
            <li class="breadcrumb-item">
              <router-link to="/home"
                ><i class="bx bx-home-alt"></i
              ></router-link>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              Blog List
            </li>
          </ol>
        </nav>

        <div class="ms-auto">
          <div class="btn-group">
            <button
              type="button"
              class="btn btn-primary"
              @click="$router.push('/add-blog-post')"
            >
              <i class="bx bxs-plus-square"></i>Add New Blog
            </button>
          </div>
        </div>
      </div>
      <h6 class="mb-0 text-uppercase">Blog List</h6>
      <hr />
      <div class="card">
        <div class="card-body">
          <div class="table-responsive">
            <table id="example" class="table table-striped table-bordered">
              <thead>
                <tr>
                  <th>SL</th>
                  <th>Blog Title</th>
                  <th>Blog Photo</th>
                  <th>Author</th>
                  <th>Author Image</th>
                  <th>Publication Date</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(data, index) in dataList" :key="index">
                  <td>{{index + 1 }}</td>
                  <!-- <td>{{data.title}}</td> -->
                  <td>{{ data.title ? data.title.length > 50 ? data.title.slice(0, 30) + ' ...' : data.title : 'No blog title found.' }}</td>
                  <td>
                    <img
                      :src="data.photo"
                      alt=""
                      height="90"
                    />
                  </td>
                  <td>{{data.authorName}}</td>
                  <td>
                    <img
                      :src="data.authorImage"
                      alt=""
                      height="90"
                    />
                  </td>
                  <td>{{ $filters.formatDate(data.publishedAt) }}</td>
                  <td>
                    <div class="d-flex order-actions">
                      <a
                        href="javascript:void(0)"
                        class="edit-button"
                        @click="$router.push('/update-blog-post/' + data.id)"
                        ><i class="bx bxs-edit text-white"></i
                      ></a>
                      <a
                        href="javascript:void(0);"
                        @click="deleteBlogPost(data.id)"
                        class="ms-3 delete-button"
                        ><i class="bx bxs-trash text-white"></i
                      ></a>
                    </div>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <th>SL</th>
                  <th>Blog Title</th>
                  <th>Blog Photo</th>
                  <th>Author</th>
                  <th>Author Image</th>
                  <th>Publication Date</th>
                  <th>Action</th>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script src='../js/blog-post.js'></script>


<style scoped>
.edit-button {
  background-color: #17a00e;
}
.delete-button {
  background-color: #f41127;
}
</style>
