import { authHeader } from "../../../../auth";
export default {
    name: "TransactionBeeHomePageEasyPayment",
    data() {
        return {
            requestUrl: this.$serverURL + this.$api.product.productSizeGuide,
            dataList: [],
            formData: {
                categoryName: "",
                guide: "",
            },
            singleData: {
                categoryName: "",
                guide: "",
            },
        }
    },
    async created() {
        document.title = "Hyman Fashion - Product Size Guide";
        await this.getData();
        this.loadScripts();
    },
    methods: {
        async getData() {
            await this.getDataList(this.requestUrl).then(response => {
                if (response.statusCode == 200) {
                    this.dataList = response.data;
                }
            });
        },

        checkValidation(data) {
            if (!data.price) {
                this.showToaster('Please enter price!')
                return false;
            } 
            if (!data.packageType) {
                this.showToaster('Please select package type!')
                return false;
            }
            if (!data.packageName) {
                this.showToaster('Please enter package name!')
                return false;
            }
            if (!data.description) {
                this.showToaster('Please enter description!')
                return false;
            }
            if (!data.features) {
                this.showToaster('Please enter features!')
                return false;
            }
            if (!data.buttonText) {
                this.showToaster('Please enter button text!')
                return false;
            }
            if (!data.buttonLink) {
                this.showToaster('Please enter button link!')
                return false;
            }
            return true;
        },

        async createContent() {
            try {
                if (this.checkValidation(this.formData)) {
                    const data = {
                        categoryName: this.formData.categoryName,
                        guide: this.formData.guide,

                    }
                    this.$swal.showLoading();
                    await this.postData(this.requestUrl, data).then(async (response) => {
                        if (response.statusCode == 201) {
                            this.formData = {
                                categoryName: "",
                                guide: "",
                            };
                            this.showToaster(response.message, 'success');
                            await this.getData();
                            document.getElementById('add-close-btn').click();
                        }
                    });
                }
            } catch (error) {
                console.log(error);
            }
        },
        editData(data) {
            this.singleData = { ...data };
        },
        update: async function () {
            if (this.checkValidation(this.singleData)) {
                const data = {
                    categoryName: this.singleData.categoryName,
                    guide: this.singleData.guide,
                }
                let url = this.requestUrl + '/' + this.singleData.id;
                this.$swal.showLoading();
                await this.updateData(url, data).then(async (response) => {
                    if (response.statusCode == 200) {
                        this.showToaster(response.message, 'success');
                        this.singleData = {};
                        await this.getData();
                        document.getElementById('edit-close-btn').click();
                    }
                });
            }
        },

        deleteData: async function (id) {
            this.$swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        let config = {
                            method: 'DELETE',
                            url: this.requestUrl + '/' + id,
                            headers: {
                                "Authorization": authHeader(),
                            }
                        }
                        await this.deleteDataToBackend(config);
                        await this.getDataList(this.requestUrl);

                    } catch (error) {
                        this.$swal.fire({
                            icon: "error",
                            text: "Something went wrong, please try agian later." + error
                        });
                    }
                }
            })
        }
    }
}