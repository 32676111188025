<template>
    <div class="page-wrapper">
        <div class="page-content">
            <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb mb-0 p-0">
                        <li class="breadcrumb-item">
                            <router-link to="/home"><i class="bx bx-home-alt"></i></router-link>
                        </li>
                        <li class="breadcrumb-item active" aria-current="page">
                            Product Perks List
                        </li>
                    </ol>
                </nav>

                <div class="ms-auto">
                    <div class="btn-group">
                        <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#addModal">
                            <i class="bx bxs-plus-square"></i>Add Product Perk
                        </button>
                    </div>
                    <!-- Add Modal -->

                    <div class="modal fade" id="addModal" tabindex="-1" aria-labelledby="addModalLabel" aria-hidden="true">
                        <form method="post" action="javascript:void(0);" enctype="multipart/form-data">
                            <div class="modal-dialog modal-lg">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h5 class="modal-title" id="addModalLabel">Add New Product Perk</h5>
                                        <button type="button" class="btn-close" data-bs-dismiss="modal"
                                            aria-label="Close"></button>
                                    </div>
                                    <div class="modal-body">
                                        <div class="row mb-3">
                                            <div class="col-md-12">
                                                <label class="form-label" for="title">Perk Title:</label>
                                                <input type="text" class="form-control" v-model="title"
                                                    id="title" />
                                            </div>
                                        </div>
                                        <div class="row mb-3">
                                            <div class="col-md-12">
                                                <label class="form-label" for="title">Perk Description:</label>
                                                <textarea type="text" class="form-control" v-model="description"
                                                    id="title"></textarea>
                                            </div>
                                        </div>
                                        <div class="row mb-3">
                                            <div class="col-md-12 mb-3">
                                                <label class="form-label">Image : <strong>[Preferred Image Size: 500X500, Max Upload Limit: 1MB]</strong></label>
                                                <input class="form-control mt-3" type="file" ref="blockImage"
                                                    accept="image/*" @change="uploadFeaturedImage" />
                                            </div>
                                            <div class="col-md-6">
                                                <img  v-if="previewImage"
                                                    :src="previewImage" height="70" />
                                                <img  v-else
                                                    src="/core/assets/images/400.png" height="70" width="100" />
                                            </div>
                                        </div>
                                    </div>

                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                                            Close
                                        </button>
                                        <button @click="addCare()" type="button" class="btn btn-primary">
                                            Save
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>

                    <!-- Add Modal -->
                    <!-- Edit Role Modal -->

                    <div class="modal fade" id="editModal" tabindex="-1" aria-labelledby="addModalLabel" aria-hidden="true">
                        <form method="post" action="javascript:void(0);" enctype="multipart/form-data">
                            <div class="modal-dialog modal-lg">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h5 class="modal-title" id="addModalLabel">Update Product Perk</h5>
                                        <button type="button" class="btn-close" data-bs-dismiss="modal"
                                            aria-label="Close"></button>
                                    </div>
                                    <div class="modal-body">
                                        <div class="row mb-3">
                                            <div class="col-md-12">
                                                <label class="form-label" for="title">Perk Title :</label>
                                                <input type="text" class="form-control" v-model="singleData.title"
                                                    id="title" />
                                            </div>
                                        </div>
                                        <div class="row mb-3">
                                            <div class="col-md-12">
                                                <label class="form-label" for="title">Perk Description :</label>
                                                <textarea type="text" class="form-control" v-model="singleData.description"
                                                    id="title"></textarea>
                                            </div>
                                        </div>
                                        <div class="row mb-3">
                                            <div class="col-md-12 mb-3">
                                                <label class="form-label">Image : <strong>[Preferred Image Size: 70X70, Max Upload Limit: 1MB]</strong></label>
                                                <input class="form-control mt-3" type="file" id="blockUpdateImage"
                                                    ref="blockUpdateImage" accept="image/*" @change="updateFeaturedImage" />
                                            </div>
                                            <div class="col-md-6">
                                                <img  v-if="updatePreviewImage"
                                                    :src="updatePreviewImage" height="70" />
                                                <img  v-else-if="singleData.image"
                                                    :src="singleData.image" height="70" />
                                                <img  v-else
                                                    src="/core/assets/images/400.png" height="70" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                                            Close
                                        </button>
                                        <button type="button" @click="updateCare()" class="btn btn-primary">
                                            Update
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>

                    <!-- Edit Role Modal -->
                </div>
            </div>
            <h6 class="mb-0 text-uppercase">Product Care List</h6>
            <hr />
            <div class="card">
                <div class="card-body">
                    <div class="table-responsive">
                        <table id="example" class="table table-striped table-bordered">
                            <thead>
                                <tr>
                                    <th>SL</th>
                                    <th>Perk Title</th>
                                    <th>Perk Description</th>
                                    <th>Image</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(data, index) in dataList" :key="index">
                                    <td>{{ index + 1 }}</td>
                                    <td>{{ data.title }}</td>
                                    <td>
                                        <span class="metaModal" data-bs-toggle="modal" data-bs-target="#showModal" @click="updateModalValue(data.description)">
                                            {{ data.description ? data.description.slice(0, 30) + '...' : 'No description found.' }}
                                        </span>
                                    </td>
                                    <td>
                                        <img :src="data.image" alt="" height="50" width="50" />
                                    </td>
                                    <td>
                                        <div class="d-flex order-actions">
                                            <a href="javascript:void(0);" v-on:click="editCare(data)"
                                                data-bs-toggle="modal" data-bs-target="#editModal" class="edit-button"><i
                                                    class="bx bxs-edit text-white"></i></a>
                                            <a href="javascript:void(0);" v-on:click="deleteCare(data.id)"
                                                class="ms-3 delete-button"><i class="bx bxs-trash text-white"></i></a>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th>SL</th>
                                    <th>Perk Title</th>
                                    <th>Perk Description</th>
                                    <th>Image</th>
                                    <th>Action</th>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade" id="showModal" tabindex="-1" aria-labelledby="showModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="showModalLabel">Description</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <p class="form-control mb-1" type="text" v-html="description"></p>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                            Close
                        </button>
                    </div>
                </div>
            </div>
        </div>
</div></template>

<script src='../js/product-perk.js'></script>


<style scoped>.edit-button {
    background-color: #17a00e;
}

.delete-button {
    background-color: #f41127;
}
.metaModal {
    color: black;
    cursor: pointer;
    text-decoration: none;
    display: block;
}
.metaModal:hover {
    cursor: pointer;
    color: #09f;
}
</style>