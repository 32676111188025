<template>
    <div class="page-wrapper">
        <div class="page-content">
            <div class="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                <div class="breadcrumb-title pe-3">Site Page Title</div>
                <div class="ps-3">
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb mb-0 p-0">
                            <li class="breadcrumb-item"><router-link to="/home"><i class="bx bx-home-alt"></i></router-link></li>
                            <li class="breadcrumb-item active" aria-current="page">Site Page Title CMS</li>
                        </ol>
                    </nav>
                </div>
            </div>
            <div class="card">
                <div class="card-body">
                    <h5 class="card-title">Site Page Title CMS</h5>
                    <hr/>
                    <div class="form-body mt-4">
                        <div class="row">
                            <div class="col-lg-8">
                                <div class="col-md-12 mb-3">
                                    <label class="form-label">Signin Page Title:</label>
                                    <input type="text" v-model="cmsData.signinPageTitle" class="form-control">
                                </div>
                                <div class="col-md-12 mb-3">
                                    <label class="form-label">Signin Page Title:</label>
                                    <input type="text" v-model="cmsData.signupPageTitle" class="form-control">
                                </div>
                                <div class="col-md-12 mb-3">
                                    <label class="form-label">Home Page Title:</label>
                                    <input type="text" v-model="cmsData.homePageTitle" class="form-control">
                                </div>
                                <div class="col-md-12 mb-3">
                                    <label class="form-label">About Us Page Title:</label>
                                    <input type="text" v-model="cmsData.aboutUsPageTitle" class="form-control">
                                </div>
                                <div class="col-md-12 mb-3">
                                    <label class="form-label">Shop Page Title:</label>
                                    <input type="text" v-model="cmsData.shopPageTitle" class="form-control">
                                </div>
                                <div class="col-md-12 mb-3">
                                    <label class="form-label">Product Details Page Title:</label>
                                    <input type="text" v-model="cmsData.productDetailsPageTitle" class="form-control">
                                </div>
                                <div class="col-md-12 mb-3">
                                    <label class="form-label">Wishlist Page Title:</label>
                                    <input type="text" v-model="cmsData.wishlistPageTitle" class="form-control">
                                </div>
                                <div class="col-md-12 mb-3">
                                    <label class="form-label">Cart Page Title:</label>
                                    <input type="text" v-model="cmsData.cartPageTitle" class="form-control">
                                </div>
                                <div class="col-md-12 mb-3">
                                    <label class="form-label">Sales Page Title:</label>
                                    <input type="text" v-model="cmsData.salesPageTitle" class="form-control">
                                </div>
                                <div class="col-md-12 mb-3">
                                    <label class="form-label">Contact Us Page Title:</label>
                                    <input type="text" v-model="cmsData.contactUsPageTitle" class="form-control">
                                </div>
                                <div class="col-md-12 mb-3">
                                    <label class="form-label">Collection Page Title:</label>
                                    <input type="text" v-model="cmsData.collectionPageTitle" class="form-control">
                                </div>
                                <div class="col-md-12 mb-3">
                                    <label class="form-label">User Account Page Title:</label>
                                    <input type="text" v-model="cmsData.userPageTitle" class="form-control">
                                </div>
                                <div class="col-md-12 mb-3">
                                    <label class="form-label">Checkout Page Title:</label>
                                    <input type="text" v-model="cmsData.checkoutPageTitle" class="form-control">
                                </div>
                                <div class="col-md-12 mb-3">
                                    <label class="form-label">Blog Page Title:</label>
                                    <input type="text" v-model="cmsData.blogPageTitle" class="form-control">
                                </div>
                                <div class="col-md-12 mb-3">
                                    <label class="form-label">Blog Details Page Title:</label>
                                    <input type="text" v-model="cmsData.blogDetailsPageTitle" class="form-control">
                                </div>
                                <div class="col-md-12 mb-3">
                                    <label class="form-label">Forgot Password Page Title:</label>
                                    <input type="text" v-model="cmsData.forgotPasswordPageTitle" class="form-control">
                                </div>
                                <div class="col-md-12 mb-3">
                                    <label class="form-label">Reset Password Page Title:</label>
                                    <input type="text" v-model="cmsData.resetPasswordPageTitle" class="form-control">
                                </div>
                                <div class="col-md-12 mb-3">
                                    <label class="form-label">Terms & Conditions Page Title:</label>
                                    <input type="text" v-model="cmsData.termsPageTitle" class="form-control">
                                </div>
                                <div class="col-md-12 mb-3">
                                    <label class="form-label">Privacy Policy Page Title:</label>
                                    <input type="text" v-model="cmsData.privacyPageTitle" class="form-control">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-8">
                                <div class="d-flex">
                                    <button v-on:click="update()" type="button" class="btn btn-primary">Save</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script src="../js/site-page-title-cms.js"></script>